import axios from 'axios';

// Base URL for the API
const API_URL =  process.env.REACT_APP_API_URL ||  "api" 

// Get all batches
export const getBatches = async () => {
  const response = await axios.get(`${API_URL}/batches`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

// Get a batch by ID
export const getBatchById = async (id) => {
  const response = await axios.get(`${API_URL}/batches/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

// Create a new batch
export const createBatch = async (batchData) => {
  const response = await axios.post(`${API_URL}/batches`, batchData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

// Update a batch by ID
export const updateBatch = async (id, batchData) => {
  const response = await axios.put(`${API_URL}/batches/${id}`, batchData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

// Delete a batch by ID
export const deleteBatch = async (id) => {
  const response = await axios.delete(`${API_URL}/batches/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

// Add students to a batch
export const addStudentsToBatch = async (id, studentIds) => {
  const response = await axios.put(
    `${API_URL}/batches/${id}/addStudents`,
    { studentIds },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }
  );
  return response.data;
};

// Remove students from a batch
export const removeStudentsFromBatch = async (id, studentIds) => {
  const response = await axios.put(
    `${API_URL}/batches/${id}/removeStudents`,
    { studentIds },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }
  );
  return response.data;
};
export const getBatchNamesAndStudentCounts = async () => {
  try {
    const batches = await getBatches();

    // Ensure batches is an array
    if (!Array.isArray(batches)) {
      console.error('Invalid data format received:', batches);
      return [];
    }

    // Transform batches data to get batch names and student counts
    const batchDetails = batches.map(batch => ({
      batchName: batch.batchName,
      studentCount: batch.students.length, // Assuming students is an array of student objects
    }));

    return batchDetails;
  } catch (error) {
    console.error('Error fetching batch names and student counts:', error);
    throw error;
  }
};

import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import AdminProfile from '../../Component/profile';

const IbProfile = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="pages" title="Ib" />
            <AdminProfile />
        </Fragment>
    );
};
export default IbProfile;
import axios from 'axios';
import { ADMIN_URL, IB_URL, STUDENT_URL } from '../Constant';

// Base URL for the API 
// if not found then same domain can be used to deploy backend 
const API_URL =  process.env.REACT_APP_API_URL ||  "api"
console.log("API URL", process.env.REACT_APP_API_URL);

// Register a new user
export const register = async (userData) => {
  const response = await axios.post(`${API_URL}/auth/register`, userData);
  return response.data;
};

// Register a new student
export const studentRegister = async (userData) => {
  const response = await axios.post(`${API_URL}/auth/register/student`, userData);
  return response.data;
};

// Login user
export const login = async (email, password) => {
  console.log("email", email);
  const response = await axios.post(`${API_URL}/auth/login`, { email, password });
  console.log("API URL", API_URL);
  console.log(response.data);

  if (response.data.token) {
    // Store token in localStorage
    localStorage.setItem('token', response.data.token);
    // Set the authorization header for future requests
    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
  }
  return response.data;
};


// Register a new IB
export const IBRegister = async (userData) => {
  const response = await axios.post(`${API_URL}/auth/register/ib`, userData);
  return response.data;
};

// IB login
export const IBlogin = async (email, password) => {
  const response = await axios.post(`${API_URL}/auth/login/ib`, { email, password });
  if (response.data.token) {
    // Store token in localStorage
    localStorage.setItem('token', response.data.token);
    // Set the authorization header for future requests
    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
  }
  return response.data;
};

// Student login
export const studentLogin = async (email, password) => {
  const response = await axios.post(`${API_URL}/auth/login/student`, { email, password });
  if (response.data.token) {
    // Store token in localStorage
    localStorage.setItem('token', response.data.token);
    // Set the authorization header for future requests
    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
  }
  return response.data;
};

// Google Login
export const googleLogin = async (googleUserData) => {
  const response = await axios.post(`${API_URL}/auth/google-login`, googleUserData);
  if (response.data.token) {
    // Store token in localStorage
    localStorage.setItem('token', response.data.token);
    // Set the authorization header for future requests
    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
  }
  return response.data;
};

// Logout user
export const logout = () => {
  localStorage.removeItem('token');
  // Remove the authorization header
  delete axios.defaults.headers.common['Authorization'];
};

// Get the current user (assuming the backend has an endpoint for this)
export const getCurrentUser = async () => {
  const token = localStorage.getItem('token');
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const response = await axios.get(`${API_URL}/auth/me`);
    return response.data;
  }
  return null;
};

// Check if the user is authenticated
export const isAuthenticated = () => {
  return !!localStorage.getItem('token');
};

// Set up Axios interceptor for handling expired token and redirection
axios.interceptors.response.use(
  (response) => {
    // If the response is successful, just return it
    return response;
  },
  (error) => {
    if (error.response) {
      // Server responded with a status other than 200 range
      const status = error.response.status;
      const message = error.response.data.message || "An error occurred. Please try again later.";

      if (status === 401) {
        // Token expired, remove token from localStorage
        localStorage.removeItem('token');
        // Redirect to appropriate login page based on the request URL
        const requestUrl = error.config.url;

        if (requestUrl.includes('/auth/login/ib')) {
          window.location.href = `${IB_URL}/ib/login`;
        } else if (requestUrl.includes('/auth/login/student')) {
          window.location.href = `${STUDENT_URL}/studentlogin`;
        } else {
          window.location.href = `${ADMIN_URL}/adminlogin`;
        }
      }

      return Promise.reject(new Error(`Request failed with status ${message}`));
    } else if (error.request) {
      // Request was made but no response was received
      return Promise.reject(new Error('No response received from the server. Please check your network connection.'));
    } else {
      // Something else caused the error
      return Promise.reject(new Error(`Error: ${error.message}`));
    }
  }
);

// Include token in API requests
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

import { HomeSvg, SamplePageSvg, LearningSvg, EmailSvg, KnowledgebaseSvg, AdminUsersSvg, GallarySvg, ChartsSvg, FormsSvg, SettingSVG, SupportTicketSvg, TaskSvg } from '../../Data/svgIcons';
import { ADMIN_URL } from '../../Constant';
export const MENUITEMS = [
    {
        menutitle: 'DashBoard',
        Items: [
            {
                title: 'DashBoard', icon: GallarySvg, type: 'link', active: false, path: `${ADMIN_URL}/dashboard`
            },
        ]
    },
    {
        menutitle: 'Students',
        Items: [
            {
                title: 'Students', icon: KnowledgebaseSvg, type: 'link', active: false, path: `${ADMIN_URL}/students`
            },
        ]
    },
    {
        menutitle: 'Add Events',
        Items: [
            {
                title: 'Add Events', icon: KnowledgebaseSvg, type: 'link', active: false, path: `${ADMIN_URL}/add-events`
            },
        ]
    },
    {
        menutitle: 'Form',
        Items: [
            {
                title: 'Form', icon: FormsSvg, type: 'link', active: false, path: `${ADMIN_URL}/form`
            },
        ]
    },
    // {
    //     menutitle: 'Create Form',
    //     Items: [
    //         {
    //             title: 'Create Form', icon: FormsSvg, type: 'link', active: false, path: `${ADMIN_URL}/create-form`
    //         },
    //     ]
    // },
    // {
    //     menutitle: 'View Form',
    //     Items: [
    //         {
    //             title: 'View Form', icon: LearningSvg, type: 'link', active: false, path: `${ADMIN_URL}/pending`
    //         }
    //     ]
    // },
    {
        menutitle: 'Batch',
        Items: [
            {
                title: 'Batch', icon: ChartsSvg, type: 'link', active: false, path: `${ADMIN_URL}/create-batch`
            },
        ]
    },
    {
        menutitle: 'Send Message',
        Items: [
            {                                       // sub for nested and link for linked
                title: 'Send Message', icon: HomeSvg, type: 'sub', active: false, children: [
                    //childrens
                    { path: `${ADMIN_URL}/email`, icon: EmailSvg, title: 'Email ', type: 'link' },
                    { path: `${ADMIN_URL}/whatsapp`, icon: SamplePageSvg, title: 'Whatsapp', type: 'link' }
                ]
            },
        ]
    },
    {
        menutitle: 'Add IB',
        Items: [
            {
                title: 'Add IB', icon: ChartsSvg, type: 'link', active: false, path: `${ADMIN_URL}/add-ib`
            },
        ]
    },
    // {
    //     menutitle: 'Profile',
    //     Items: [
    //         {
    //             title: 'Profile', icon: AdminUsersSvg, type: 'link', active: false, path: `${ADMIN_URL}/profile`
    //         },
    //     ]
    // },
    {
        menutitle: 'Access Control',
        Items: [
            {
                title: 'Access Control', icon: TaskSvg, type: 'link', active: false, path: `${ADMIN_URL}/access-control`
            },
        ]
    },
    {
        menutitle: 'Setting',
        Items: [
            {                                       // sub for nested and link for linked
                title: 'Settings ...', icon: SettingSVG, type: 'sub', active: false, children: [
                    //childrens
                    { path: `${ADMIN_URL}/mail-setup`, icon: SamplePageSvg, title: 'Mail Setup', type: 'link' },
                    { path: `${ADMIN_URL}/wapikon-setup`, icon: SamplePageSvg, title: 'Whatsapp', type: 'link' }
                ]
            },
        ]
    },
    {
        menutitle: 'Study Material',
        Items: [
            {
                title: 'Study Material', icon: ChartsSvg, type: 'link', active: false, path: `${ADMIN_URL}/study-material`
            },
        ]
    },
    {
        menutitle: 'Courses',
        Items: [
            {
                title: 'Courses', icon: ChartsSvg, type: 'link', active: false, path: `${ADMIN_URL}/courses`
            },
        ]
    },
    {
        menutitle: 'Brokers',
        Items: [
            {
                title: 'Brokers', icon: ChartsSvg, type: 'link', active: false, path: `${ADMIN_URL}/brokers`
            },
        ]
    },
];

import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Button, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { H5 } from '../../AbstractElements';
import { listTemplates, getTemplateBody } from '../../Services/wapikonService';
import { createTrigger, getTriggers, updateTrigger, deleteTrigger } from '../../Services/triggerService';

const TriggerTemplateMapping = () => {
  const [mappings, setMappings] = useState([{ trigger: '', template: '', variables: [] }]);
  const [templates, setTemplates] = useState([]);
  const studentFields = ['name', 'email', 'status', "batch", "course", "paymentId", "courseStatus"];
  const ibFields = ['name', 'email', 'phone', 'referralCode', "status"];

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const templatesData = await listTemplates();
        if (Array.isArray(templatesData.response)) {
          setTemplates(templatesData.response);
        } else {
          console.error('Error: Expected templatesData to be an array');
          setTemplates([]);
        }
      } catch (error) {
        console.error('Error fetching templates:', error);
        setTemplates([]);
      }
    };

    fetchTemplates();
    fetchExistingTriggers();
  }, []);

  const fetchExistingTriggers = async () => {
    try {
      const existingTriggers = await getTriggers();
      setMappings(existingTriggers.map(trigger => ({
        _id: trigger._id, 
        trigger: trigger.event,
        template: trigger.templateName,
        variables: trigger.variables.map(variable => ({
          variable: variable.name,
          value: variable.value,
        }))
      })));
    } catch (error) {
      console.error('Error fetching triggers:', error);
    }
  };

  const handleAddMapping = () => {
    setMappings([...mappings, { trigger: '', template: '', variables: [] }]);
  };

  const handleInputChange = (index, field, value) => {
    const newMappings = [...mappings];
    newMappings[index][field] = value;
    setMappings(newMappings);

    if (field === 'template' && value) {
      fetchTemplateVariables(index, value);
    }
  };

  const fetchTemplateVariables = async (index, templateName) => {
    try {
      const templateData = await getTemplateBody(templateName);
      if (templateData.success && templateData.requestBody) {
        const newMappings = [...mappings];
        newMappings[index].variables = templateData.requestBody.bodyVariables.map(variable => ({
          variable,
          value: ''
        }));
        setMappings(newMappings);
      } else {
        console.error('Error: Expected requestBody in templateData');
      }
    } catch (error) {
      console.error('Error fetching template variables:', error);
    }
  };

  const handleVariableChange = (mappingIndex, variableIndex, value) => {
    const newMappings = [...mappings];
    newMappings[mappingIndex].variables[variableIndex].value = value;
    setMappings(newMappings);
  };

  const handleSaveMappings = async () => {
    try {
      for (const mapping of mappings) {
        const triggerData = {
          event: mapping.trigger,
          templateName: mapping.template,
          variables: mapping.variables.map(variable => ({
            name: variable.variable,
            value: variable.value
          }))
        };

        if (mapping._id) {
          await updateTrigger(mapping._id, triggerData);
        } else {
          await createTrigger(triggerData);
        }
      }
      alert('Triggers saved successfully');
    } catch (error) {
      console.error('Error saving triggers:', error);
      alert('Failed to save triggers');
    }
  };

  const handleDeleteMapping = async (index) => {
    try {
      const mappingToDelete = mappings[index];
      if (mappingToDelete._id) {
        await deleteTrigger(mappingToDelete._id);
      }
      setMappings(mappings.filter((_, i) => i !== index));
      alert('Trigger deleted successfully');
    } catch (error) {
      console.error('Error deleting trigger:', error);
      alert('Failed to delete trigger');
    }
  };

  return (
    <Card style={{ width: '95%', margin: '10px auto', paddingRight: '5px' }} className="dash-35">
      <CardHeader>
        <H5>Manage Triggers, Templates, and Variable Mapping</H5>
      </CardHeader>
      <CardBody>
        {mappings.map((mapping, index) => (
          <Row key={index} className="mb-4">
            <Col md={3}>
              <FormGroup>
                <Label for={`trigger-${index}`}>Trigger</Label>
                <Input
                  type="select"
                  id={`trigger-${index}`}
                  value={mapping.trigger}
                  onChange={(e) => handleInputChange(index, 'trigger', e.target.value)}
                >
                  <option value="">Select Trigger</option>
                  <option value="student:created">student:created</option>
                  <option value="student:updated">student:updated</option>
                  <option value="ib:created">ib:created</option>
                  <option value="formResponse:submitted">formResponse:submitted</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for={`template-${index}`}>Template</Label>
                <Input
                  type="select"
                  id={`template-${index}`}
                  value={mapping.template}
                  onChange={(e) => handleInputChange(index, 'template', e.target.value)}
                >
                  <option value="">Select Template</option>
                  {templates.map((template) => (
                    <option key={template} value={template}>
                      {template}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            {(mapping.trigger !== 'formResponse:submitted') && (
              <Col md={6}>
                <FormGroup>
                  <Label>Map Variables</Label>
                  {mapping.variables.map((variableMapping, varIndex) => (
                    <div key={varIndex} className="mb-3">
                      <Label>{variableMapping.variable}</Label>
                      <div className="d-flex">
                        <Input
                          type="select"
                          className="me-2"
                          value={variableMapping.value || ''}
                          onChange={(e) => handleVariableChange(index, varIndex, e.target.value)}
                        >
                          <option value="">Select Field</option>
                          {(mapping.trigger.includes('ib') ? ibFields : studentFields).map((field) => (
                            <option key={field} value={field}>
                              {field}
                            </option>
                          ))}
                          <option value="custom">Custom Input</option>
                        </Input>
                        {variableMapping.value === 'custom' && (
                          <Input
                            type="text"
                            placeholder="Enter custom value"
                            value={variableMapping.customValue || ''}
                            onChange={(e) => handleVariableChange(index, varIndex, e.target.value)}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </FormGroup>
              </Col>
            )}
            <Col md={12} className="d-flex justify-content-end">
              <Button color="danger" onClick={() => handleDeleteMapping(index)}>
                Delete
              </Button>
            </Col>
          </Row>
        ))}
        <Col className='d-flex gap-2'>
          <Button color="primary" onClick={handleAddMapping}>
            Add Another Trigger
          </Button>
          <Button color="success" onClick={handleSaveMappings}>
            Save Mappings
          </Button>
        </Col>
      </CardBody>
    </Card>
  );
};

export default TriggerTemplateMapping;

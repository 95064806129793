import React from 'react'
import OnGoing from './OnGoing';

function CreateFormpage() {
    const val = true;
    return (
        <>
            <OnGoing val={val} />
        </>
    )
}

export default CreateFormpage;
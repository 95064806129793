import axios from 'axios';

// Base URL for the API
const API_URL = process.env.REACT_APP_API_URL || "api"

// Set up the authorization token
const setAuthToken = () => {
  const token = localStorage.getItem('studentToken');
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

// Student Login
export const studentLogin = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}/auth/login/student`, { email, password });
    const { token } = response.data;

    // Set token to axios headers for future requests
    localStorage.setItem('studentToken', token);
    setAuthToken();

    return response.data;
  } catch (error) {
    console.error('Error logging in student:', error.response?.data || error.message);
    throw error;
  }
};

// Get student profile
export const getStudentProfile = async () => {
  try {
    setAuthToken();  // Ensure the token is set before making the request
    const response = await axios.get(`${API_URL}/studentPersonal/profile`);
    return response.data;
  } catch (error) {
    console.error('Error fetching student profile:', error.response?.data || error.message);
    throw error;
  }
};

// Update student profile
export const updateStudentProfile = async (profileData) => {
  try {
    setAuthToken();  // Ensure the token is set before making the request
    const response = await axios.put(`${API_URL}/studentPersonal/profile`, profileData);
    return response.data;
  } catch (error) {
    console.error('Error updating student profile:', error.response?.data || error.message);
    throw error;
  }
};

// Upload student profile picture
export const uploadStudentProfilePicture = async (formData) => {
  try {
    setAuthToken();  // Ensure the token is set before making the request
    const response = await axios.post(`${API_URL}/studentPersonal/profile/courses`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading student profile picture:', error.response?.data || error.message);
    throw error;
  }
};


// Get student courses
export const getStudentCourses = async () => {
  try {
    setAuthToken();  // Ensure the token is set before making the request
    const response = await axios.get(`${API_URL}/studentPersonal/courses`);
    return response.data;
  } catch (error) {
    console.error('Error fetching student courses:', error.response?.data || error.message);
    throw error;
  }
}

// Get student study materials
export const getStudentStudyMaterials = async () => {
  try {
    setAuthToken();  // Ensure the token is set before making the request
    const response = await axios.get(`${API_URL}/studentPersonal/studyMaterials`);
    return response.data;
  } catch (error) {
    console.error('Error fetching student study materials:', error.response?.data || error.message);
    throw error;
  }
};

// Logout student
export const studentLogout = () => {
  localStorage.removeItem('studentToken');
  setAuthToken();  // Remove authorization header
};

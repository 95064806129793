import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Form, FormGroup, Input, Label, Button, Row, Col } from 'reactstrap';
import { H4, H6 } from '../../AbstractElements';
import { Save, EmailAddress, Mobile } from '../../Constant';
import { getIbProfile, updateIbProfile } from '../../Services/ibPersonalService'; // Using IB-specific service

const ProfileEdit = ({ handleSaveProfile }) => {
  const [profile, setProfile] = useState({
    name: '',
    email: '',
    mobilePrefix: '+1',  // Default mobile prefix
    mobile: ''
  });

  const countryOptions = [
    { code: '+1', name: 'United States' },
    { code: '+44', name: 'United Kingdom' },
    { code: '+91', name: 'India' },
    { code: '+61', name: 'Australia' },
    { code: '+81', name: 'Japan' },
    { code: '+86', name: 'China' },
    { code: '+49', name: 'Germany' },
    { code: '+33', name: 'France' }
  ];

  useEffect(() => {
    const fetchIbProfile = async () => {
      try {
        const ibProfile = await getIbProfile();
        setProfile({
          name: ibProfile.name,
          email: ibProfile.email,
          mobile: ibProfile.phone || '',  // Assuming phone number is stored in `phone`
          mobilePrefix: '+91' // Assuming default prefix
        });
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };

    fetchIbProfile();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const updatedProfile = { ...profile, phone: profile.mobilePrefix + profile.mobile };
      await updateIbProfile(updatedProfile);  // Save IB profile via ibPersonal service
      alert('Profile updated successfully!');
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Failed to update profile.');
    }
  };

  return (
    <Fragment>
      <Card>
        <CardHeader className="pb-0 mt-8">
          <H4 attrH4={{ className: 'card-title mb-0' }}>Edit Profile</H4>
          <div className="card-options">
            <a className="card-options-collapse" href="#javascript">
              <i className="fe fe-chevron-up"></i>
            </a>
            <a className="card-options-remove" href="#javascript">
              <i className="fe fe-x"></i>
            </a>
          </div>
        </CardHeader>
        <CardBody>
          <Form onSubmit={handleSave}>
            <FormGroup className="mb-3">
              <Label className="form-label">Name</Label>
              <Input
                type="text"
                name="name"
                value={profile.name}
                onChange={handleInputChange}
                className="mb-1 f-20 txt-primary"
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label className="form-label">{EmailAddress}</Label>
              <Input
                type="email"
                name="email"
                className="form-control"
                value={profile.email}
                onChange={handleInputChange}
                placeholder="your-email@domain.com"
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label className="form-label">{Mobile}</Label>
              <Row>
                <Col md="5">
                  <Input
                    type="select"
                    name="mobilePrefix"
                    value={profile.mobilePrefix}
                    onChange={handleInputChange}
                  >
                    {countryOptions.map((country) => (
                      <option key={country.code} value={country.code}>
                        {country.name} ({country.code})
                      </option>
                    ))}
                  </Input>
                </Col>
                <Col md="7">
                  <Input
                    type="tel"
                    name="mobile"
                    className="form-control"
                    value={profile.mobile}
                    onChange={handleInputChange}
                    placeholder="1234567890"
                  />
                </Col>
              </Row>
            </FormGroup>
            <div className="form-footer">
              <Button color="primary" block type="submit">{Save}</Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default ProfileEdit;

import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import ProfileCard from '../ibProfile/ProfileCard';
import ProfileEdit from '../ibProfile/ProfileEdit';
import { getIbProfile, updateIbProfile } from '../../Services/ibPersonalService'; // Use ibPersonal service
import { toast } from 'react-toastify';

const AdminProfile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [profileData, setProfileData] = useState({
    name: '',
    profilePhoto: '',
    role: '',
    bio: '',
    email: '',
    website: '',
  });

  useEffect(() => {
    fetchProfileData();
  }, []);

  const fetchProfileData = async () => {
    try {
      const data = await getIbProfile(); // Fetch IB profile using the ibPersonal service
      setProfileData({
        name: data.name,
        role: 'IB', // Assuming role is fixed for IB
        // profilePhoto: '', // Assuming the API doesn't provide a profile photo in the response
        bio: '', // Assuming the API doesn't provide a bio in the response
        email: data.email,
        website: '', // Assuming the API doesn't provide a website in the response
      });
    } catch (error) {
      console.error('Error fetching profile data:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleProfilePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileData((prevData) => ({
          ...prevData,
          profilePhoto: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSaveProfile = async () => {
    try {
      const updatedProfile = await updateIbProfile(profileData); // Update IB profile using the ibPersonal service
      setProfileData(updatedProfile);
      setIsEditing(false);
      toast.success('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Error updating profile');
    }
  };

  return (
    <Fragment>
      <Container fluid className="justify-content-center align-items-center">
        <div className="edit-profile">
          <Row className="justify-content-center">
            <Col xl="8">
              {isEditing ? (
                <ProfileEdit
                  name={profileData.name}
                  // profilePhoto={profileData.profilePhoto}
                  bio={profileData.bio}
                  email={profileData.email}
                  website={profileData.website}
                  handleInputChange={handleInputChange}
                  handleProfilePhotoChange={handleProfilePhotoChange}
                  handleSaveProfile={handleSaveProfile}
                />
              ) : (
                <ProfileCard
                  name={profileData.name}
                  // profilePhoto={profileData.profilePhoto}
                  role={profileData.role}
                  bio={profileData.bio}
                  email={profileData.email}
                  website={profileData.website}
                  isEditing={isEditing}
                  handleNameEdit={() => setIsEditing(!isEditing)}
                />
              )}
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default AdminProfile;

import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import AdminProfile from '../../Component/profile';

const Profile = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="pages" title="Profile" />
      <AdminProfile />
    </Fragment>
  );
};
export default Profile;
import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import CreateFormpage from '../../Component/Form';
import FormBuilder from '../../Component/Form Page';

const FormBuilderPage = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Form" title="Form-builder" />
      <FormBuilder />
    </Fragment>
  );
};
export default FormBuilderPage;
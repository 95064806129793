import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import Error404 from './Error404';

const NotFound = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Pages" title="Error404" />
            <Error404 />
        </Fragment>
    );
};
export default NotFound;
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || "api"

// Fetch all brokers
export const getAllBrokers = async () => {
  try {
    const response = await axios.get(`${API_URL}/brokers`);
    return response.data;
  } catch (error) {
    console.error('Error fetching brokers:', error);
    throw error;
  }
};

// Fetch a single broker by ID
export const getBrokerById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/brokers/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching broker with ID ${id}:`, error);
    throw error;
  }
};

// Create a new broker
export const createBroker = async (brokerData) => {
  try {
    const response = await axios.post(`${API_URL}/brokers/`, brokerData);
    return response.data;
  } catch (error) {
    console.error('Error creating broker:', error);
    throw error;
  }
};

// Update an existing broker
export const updateBroker = async (id, brokerData) => {
  try {
    const response = await axios.put(`${API_URL}/brokers/${id}`, brokerData);
    return response.data;
  } catch (error) {
    console.error(`Error updating broker with ID ${id}:`, error);
    throw error;
  }
};

// Delete a broker
export const deleteBroker = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/brokers/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting broker with ID ${id}:`, error);
    throw error;
  }
};

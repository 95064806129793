import React, { Fragment, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import { getGenderCounts } from '../../../Services/studentService';

const PieChartClass = () => {
    const [chartData, setChartData] = useState({
        series: [0, 0], // Default values
        options: {
            chart: {
                type: 'pie',
            },
            labels: ['Female', 'Male'],
            colors: ['#fb740d', '#544fff'],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200, // Adjusted width for smaller screens
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            legend: {
                position: 'right',
                markers: {
                    fillColors: ['#fb740d', '#544fff']
                }
            }
        }
    });

    useEffect(() => {
        const fetchGenderCounts = async () => {
            try {
                const { femaleCount, maleCount } = await getGenderCounts();
                console.log('Fetched Gender Counts:', { femaleCount, maleCount }); // Log the fetched counts
                setChartData(prevState => ({
                    ...prevState,
                    series: [femaleCount, maleCount]
                }));
            } catch (error) {
                console.error('Error fetching gender counts:', error);
            }
        };

        fetchGenderCounts();
    }, []);

    return (
        <Fragment>
            <Col xs='12' sm='12' md='4' lg='4' xl='4' className='box-col-6'>
                <Card style={{ padding: '10px', margin: '10px' }}> {/* Adjust padding and margin */}
                    <CardHeader className='text-center' style={{ fontSize: '20px', fontWeight: 'bold' }}> {/* Adjusted font size */}
                        Students Remark
                    </CardHeader>
                    <CardBody>
                        <div id='piechart' style={{ width: '100%', height: '200px' }}> {/* Adjusted height */}
                            <Chart options={chartData.options} series={chartData.series} type="pie" width='100%' height="200px" /> {/* Adjusted height */}
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    );
};

export default PieChartClass;

import React, { Fragment } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import UpcomingEventsContain from '../../../Component/studentsDashBoard/upcomingEvents';

const UpcomingEvents = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Pages" title="Upcoming Events" />
      <UpcomingEventsContain />
    </Fragment>
  );
};
export default UpcomingEvents;
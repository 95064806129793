import axios from 'axios';

// Base URL for the API
const API_URL = process.env.REACT_APP_API_URL || "api";

// Get logged-in IB's profile
export const getIbProfile = async () => {
  const response = await axios.get(`${API_URL}/ibPersonal/profile`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

// Update logged-in IB's profile
export const updateIbProfile = async (ibData) => {
  const response = await axios.put(`${API_URL}/ibPersonal/profile`, ibData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

// Get all courses associated with IB
export const getIbCourses = async () => {
  const response = await axios.get(`${API_URL}/ibPersonal/courses`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

// Get all events associated with IB (if you have events)
export const getIbEvents = async () => {
  const response = await axios.get(`${API_URL}/ibPersonal/events`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

// Get all students referred by the logged-in IB
export const getIbStudents = async () => {
  const response = await axios.get(`${API_URL}/ibPersonal/students`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

// Add a new student with a default "pending" status
export const addIbStudent = async (studentData) => {
  const response = await axios.post(`${API_URL}/ibPersonal/students`, studentData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

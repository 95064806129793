import axios from 'axios';

// Base URL for the API
const API_URL = process.env.REACT_APP_API_URL || "api"

// Get all students
export const getStudents = async (limit = 100) => {
  const response = await axios.get(`${API_URL}/students?limit=${limit}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

// Get a student by ID
export const getStudentById = async (id) => {
  const response = await axios.get(`${API_URL}/students/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

// Create a new student
export const createStudent = async (studentData) => {
  const response = await axios.post(`${API_URL}/students`, studentData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

// Update a student by ID
export const updateStudent = async (id, studentData) => {
  const response = await axios.put(`${API_URL}/students/${id}`, studentData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

// Delete a student by ID
export const deleteStudent = async (id) => {
  const response = await axios.delete(`${API_URL}/students/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};


// gender count for dashboard
export const getGenderCounts = async () => {
  try {
    const response = await axios.get(`${API_URL}/students`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    // Adjust the path based on your API response structure
    const students = response.data.students; // Example: response.data.students

    if (!students || !Array.isArray(students)) {
      console.error('Invalid data format received:', students);
      return { femaleCount: 0, maleCount: 0 };
    }

    let femaleCount = 0;
    let maleCount = 0;

    students.forEach(student => {
      if (student.gender === 'female') {
        femaleCount += 1;
      } else if (student.gender === 'male') {
        maleCount += 1;
      }
    });

    return {
      femaleCount,
      maleCount,
    };
  } catch (error) {
    console.error('Error fetching student gender counts:', error);
    throw error;
  }
};

// active/inactive count for dashboard
export const getActiveCounts = async () => {
  try {
    const response = await axios.get(`${API_URL}/students`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    // Adjust the path based on your API response structure
    const students = response.data.students; // Example: response.data.students

    if (!students || !Array.isArray(students)) {
      console.error('Invalid data format received:', students);
      return { femaleCount: 0, maleCount: 0 };
    }

    let active = 0;
    let inactive = 0;

    students.forEach(student => {
      if (student.status === 'active') {
        active += 1;
      } else if (student.status === 'inactive') {
        inactive += 1;
      }
    });

    return {
      active,
      inactive,
    };
  } catch (error) {
    console.error('Error fetching student active counts:', error);
    throw error;
  }
};

import axios from 'axios';

// Base URL for the API
const API_URL =  process.env.REACT_APP_API_URL ||  "api" 

// Get all mail setup configurations
export const getAllMailSetups = async () => {
  const response = await axios.get(`${API_URL}/mail-setup`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

// Create a new mail setup configuration
export const createMailSetup = async (mailSetupData) => {
  const response = await axios.post(`${API_URL}/mail-setup`, mailSetupData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

// Update a mail setup configuration by ID
export const updateMailSetup = async (id, mailSetupData) => {
  const response = await axios.put(`${API_URL}/mail-setup/${id}`, mailSetupData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

// Delete a mail setup configuration by ID
export const deleteMailSetup = async (id) => {
  const response = await axios.delete(`${API_URL}/mail-setup/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

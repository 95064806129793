import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import CoursePage from '../../Component/Courses';

const Courses = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Pages" title="study-material" />
      <CoursePage />
    </Fragment>
  );
};
export default Courses;
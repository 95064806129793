import { HomeSvg, SamplePageSvg, LearningSvg, KnowledgebaseSvg, GallarySvg, ChartsSvg, FormsSvg } from '../../Data/svgIcons';
import { STUDENT_URL } from '../../Constant';
export const MENUITEMS = [

    {
        menutitle: 'Profile',
        Items: [
            {
                title: 'Profile', icon: GallarySvg, type: 'link', active: false, path: `${STUDENT_URL}/profile`
            },
        ]
    },
    {
        menutitle: 'Upcoming Events',
        Items: [
            {
                title: 'Upcoming Events', icon: GallarySvg, type: 'link', active: false, path: `${STUDENT_URL}/upcoming-events`
            },
        ]
    },
    {
        menutitle: 'Enroll Course',
        Items: [
            {
                title: 'Enroll Course', icon: FormsSvg, type: 'link', active: false, path: `${STUDENT_URL}/enroll_course`
            },
        ]
    },
    {
        menutitle: 'Study Material',
        Items: [
            {
                title: 'Study Material', icon: FormsSvg, type: 'link', active: false, path: `${STUDENT_URL}/study-material`
            },
        ]
    },
    {
        menutitle: 'Open Trading Account',
        Items: [
            {
                title: 'Open Trading Account', icon: LearningSvg, type: 'link', active: false, path: `${STUDENT_URL}/trading_account`
            },
        ]
    },
    {
        menutitle: 'Enroll As IB',
        Items: [
            {
                title: 'Enroll As IB', icon: ChartsSvg, type: 'link', active: false, path: `/ib/login`
            },
        ]
    },
    {
        menutitle: 'Support',
        Items: [
            {
                title: 'Support', icon: ChartsSvg, type: 'link', active: false,external: true,path:"mailto:support@thelearningpower.com"
            },
        ]
    },
];

import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Input, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label } from 'reactstrap';
import { FaPlus, FaCopy } from 'react-icons/fa';
import { MoreHorizontal } from 'react-feather';
import { LI, UL } from '../../AbstractElements';
import { Link } from 'react-router-dom';
import { getAllForms, deleteForm, updateForm } from '../../Services/formService';
import { toast } from 'react-toastify';

const OnGoing = () => {
    const [forms, setForms] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedForm, setSelectedForm] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        formLink: '',
        expiryDate: '',
        status: 'active',
    });
    const [copiedLink, setCopiedLink] = useState('');

    useEffect(() => {
        fetchForms();
    }, []);

    const fetchForms = async () => {
        try {
            const formsData = await getAllForms();
            setForms(formsData);
        } catch (error) {
            console.error('Error fetching forms:', error);
        }
    };

    const toggleModal = () => setIsModalOpen(!isModalOpen);

    const handleEdit = (form) => {
        setSelectedForm(form);
        setFormData({
            ...form,
            // formLink: '', // Clear the form link so the user can paste a new one
        });
        toggleModal(); // Open the modal for editing
    };

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async () => {
        if (selectedForm) {
            try {
                await updateForm(selectedForm._id, formData);
                fetchForms(); // Refresh forms after update
                toggleModal(); // Close the modal
                toast.success("Form Submitted Successfully");
            } catch (error) {
                console.error('Error updating form:', error);
                toast.error('Error updating form:', error);
            }
        }
    };

    const handleDelete = async (formId) => {
        try {
            await deleteForm(formId);
            fetchForms(); // Refresh forms after delete
            toast.success("Form Deleted Successfully");
        } catch (error) {
            console.error('Error deleting form:', error);
            toast.error('Error deleting form:', error);
        }
    };

    const handleCopyLink = () => {
        navigator.clipboard.writeText(formData.formLink);
        setCopiedLink('Link copied to clipboard!');
        toast.success('Link copied to clipboard!');
        setTimeout(() => setCopiedLink(''), 2000);
    };

    const filteredForms = forms.filter(form =>
        form.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Fragment>
            <Card style={{ width: '95%', margin: '0 auto', paddingRight: '5px' }} className="dash-35">
                <Card className='ongoing-project'>
                    <CardHeader className="card-no-border">
                        {/* Header content */}
                    </CardHeader>
                    <div className="d-flex justify-content-end gap-2" style={{ paddingRight: '4px' }}>
                        <Input
                            type="text"
                            style={{ width: '28rem' }}
                            placeholder="Search here.."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <Link to={'/form-builder'}>
                            <Button color="primary" className='badge-light d-block btn-mail'>
                                <FaPlus />
                            </Button>
                        </Link>
                    </div>
                    <CardBody className="pt-0">
                        <CardBody className="table-responsive">
                            <Table className={`table-bordernone`}>
                                <thead>
                                    <tr>
                                        <th>Form Name</th>
                                        <th>Form Link</th>
                                        <th>Date Created</th>
                                        <th>Expiry Date</th>
                                        <th>Form Expired</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        filteredForms.map((form, index) => (
                                            <tr key={index}>

                                                <td>
                                                    <a
                                                        href={`${window.location.origin}/view-form/${form._id}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {form.title}
                                                    </a>
                                                    <Link to={`/form/${form._id}`}></Link>
                                                </td>
                                                <td>
                                                    <a
                                                        href={`${window.location.origin}/form/${form._id}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {`${window.location.origin}/form/${form._id}`}
                                                    </a>
                                                </td>
                                                <td>{new Date(form.createdAt).toLocaleDateString()}</td>
                                                <td>{form.expiryDate ? new Date(form.expiryDate).toLocaleDateString() : 'N/A'}</td>
                                                <td>{form.expiryDate && new Date(form.expiryDate) < new Date() ? 'Yes' : 'No'}</td>
                                                <td><div className={`badge ${form.status === 'active' ? 'badge-light-primary' : 'badge-light-danger'}`}>{form.status}</div></td>
                                                <td>
                                                        <div className="d-flex gap-2">
                                                                <Button color='primary' onClick={() => handleEdit(form)}>Edit</Button>
                                                                <Button color='danger' onClick={() => handleDelete(form._id)}>Delete</Button>
                                                        </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </CardBody>
                    </CardBody>
                </Card>
            </Card>

            {/* Edit Form Modal */}
            <Modal isOpen={isModalOpen} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Edit Form</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="title">Title</Label>
                            <Input
                                type="text"
                                name="title"
                                id="title"
                                value={formData.title}
                                onChange={handleFormChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="description">Description</Label>
                            <Input
                                type="textarea"
                                name="description"
                                id="description"
                                value={formData.description}
                                onChange={handleFormChange}
                            />
                        </FormGroup>
                        {/* <FormGroup>
                            <Label for="formLink">Form Link</Label>
                            <div className="d-flex align-items-center gap-2">
                                <Input
                                    type="text"
                                    name="formLink"
                                    id="formLink"
                                    placeholder="Paste your form link here"
                                    value={formData.formLink}
                                    onChange={handleFormChange}
                                />
                                <Button color="secondary" onClick={handleCopyLink}><FaCopy /></Button>
                            </div>
                            {copiedLink && <small className="text-success">{copiedLink}</small>}
                        </FormGroup> */}
                        <FormGroup>
                            <Label for="expiryDate">Expiry Date</Label>
                            <Input
                                type="date"
                                name="expiryDate"
                                id="expiryDate"
                                value={formData.expiryDate ? new Date(formData.expiryDate).toISOString().split('T')[0] : ''}
                                onChange={handleFormChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="status">Status</Label>
                            <Input
                                type="select"
                                name="status"
                                id="status"
                                value={formData.status}
                                onChange={handleFormChange}
                            >
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                            </Input>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSubmit}>Save</Button>
                    <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

export default OnGoing;

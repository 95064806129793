import React, { Fragment, useState, useEffect } from 'react';
import {
  Card, CardBody, CardHeader, Input, Media, Table, Button, Modal,
  ModalHeader, ModalBody, ModalFooter, FormGroup, Label
} from 'reactstrap';
import { H5, H6 } from '../../AbstractElements';
import { IoMdPersonAdd } from "react-icons/io";
import { LuRefreshCcw } from "react-icons/lu";
import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";
import {
  getIBs,
  createIB,
  updateIB,
  deleteIB,
} from '../../Services/ibService';
import { toast } from 'react-toastify';

const ReferralPartners = () => {
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [partners, setPartners] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchIBs();
  }, []);

  const fetchIBs = async () => {
    try {
      const data = await getIBs();
      setPartners(data);
    } catch (error) {
      console.error('Error fetching IBs:', error);
    }
  };

  const toggleModal = (modalType) => {
    switch (modalType) {
      case 'add':
        setAddModal(!addModal);
        setSelectedPartner(null); // Clear selected partner when adding new
        break;
      case 'edit':
        setEditModal(!editModal);
        break;
      case 'delete':
        setDeleteModal(!deleteModal);
        break;
      default:
        break;
    }
  };

  const handleEditClick = (partner) => {
    setSelectedPartner(partner);
    toggleModal('edit');
  };

  const handleDeleteClick = (partner) => {
    setSelectedPartner(partner);
    toggleModal('delete');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedPartner({ ...selectedPartner, [name]: value });
  };

  const handleSave = async () => {
    try {
      console.log('selectedPartner:', selectedPartner);
      if (selectedPartner._id) {
        await updateIB(selectedPartner._id, selectedPartner);
        toast.success("IB updated");
      } else {
        await createIB(selectedPartner);
        toast.success("IB Created");
      }
      fetchIBs(); // Refresh IB list after saving
      toggleModal('edit');
    } catch (error) {
      console.error('Error saving IB:', error);
      toast.error('Error saving IB:', error);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteIB(selectedPartner._id);
      fetchIBs(); // Refresh IB list after deletion
      toast.success("IB Deleted");
      toggleModal('delete');
    } catch (error) {
      toast.success('Error deleting IB:', error);
      console.error('Error deleting IB:', error);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Filtered data based on search
  const filteredPartners = partners.filter(partner =>
    partner.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    partner.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Fragment>
      <Card style={{ width: '95%', margin: '0 auto', paddingRight: '5px' }} className="dash-35">
        <Card className='ongoing-project'>
          <CardHeader className="card-no-border">
            <Media className="media-dashboard">
              <Media body>
                <H5 attrH5={{ className: 'mb-0' }}>Referral Partner Data</H5>
              </Media>
            </Media>
          </CardHeader>
          <div className="d-flex flex-wrap justify-content-end align-items-center gap-2 mb-3">
  <div className="d-flex flex-wrap gap-2">
    <Button outline color="primary" onClick={fetchIBs}>
      <LuRefreshCcw />
    </Button>
    <Button color="primary" onClick={() => toggleModal('add')}>
      <FaPlus /> Add IB
    </Button>
  </div>
  <Input
    type="text"
    style={{ maxWidth: '300px' }}
    placeholder="Search here.."
    value={searchTerm}
    onChange={handleSearch}
  />
</div>

          <CardBody className="pt-0">
            <Table className="table-bordernone" responsive>
              <thead>
                <tr>
                  <th><H5>ID</H5></th>
                  <th><H5>Name</H5></th>
                  <th><H5>Email</H5></th>
                  <th><H5>Mobile Number</H5></th>
                  <th><H5>Referral Code</H5></th>
                  <th><H5>Company</H5></th>
                  <th><H5>Address</H5></th>
                  <th><H5>Status</H5></th>
                  <th><H5>Actions</H5></th>
                </tr>
              </thead>
              <tbody>
                {
                  filteredPartners.map((partner) => (
                    <tr key={partner._id}>
                      <td><H6>{partner._id}</H6></td>
                      <td><H6>{partner.name}</H6></td>
                      <td><H6>{partner.email}</H6></td>
                      <td><H6>{partner.phone || 'N/A'}</H6></td>
                      <td><H6>{partner.referralCode || 'N/A'}</H6></td>
                      <td><H6>{partner.company || 'N/A'}</H6></td>
                      <td><H6>{partner.address || 'N/A'}</H6></td>
                      <td>
                        <div className='badge badge-light-primary'>{partner.status}</div>
                      </td>
                      <td>
                        <div className="d-flex flex-column flex-md-row justify-content-center gap-2">
                          <Button color="primary" onClick={() => handleEditClick(partner)}><FaEdit /></Button>
                          <Button color="danger" onClick={() => handleDeleteClick(partner)}><FaTrash /></Button>
                        </div>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Card>

      {/* Add/Edit Modal */}
      <Modal isOpen={addModal || editModal} toggle={() => toggleModal(editModal ? 'edit' : 'add')}>
        <ModalHeader toggle={() => toggleModal(editModal ? 'edit' : 'add')}>{editModal ? 'Edit IB' : 'Add IB'}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input
              type="text"
              name="name"
              id="name"
              value={selectedPartner?.name || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              type="email"
              name="email"
              id="email"
              value={selectedPartner?.email || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="password">Password</Label>
            <Input
              type="password"
              name="password"
              id="password"
              value={selectedPartner?.password || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="mobile">Mobile Number</Label>
            <Input
              type="text"
              name="phone"
              id="phone"
              value={selectedPartner?.phone || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="referralCode">Referral Code</Label>
            <Input
              type="text"
              name="referralCode"
              id="referralCode"
              value={selectedPartner?.referralCode || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="company">Company</Label>
            <Input
              type="text"
              name="company"
              id="company"
              value={selectedPartner?.company || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="address">Address</Label>
            <Input
              type="text"
              name="address"
              id="address"
              value={selectedPartner?.address || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="status">Status</Label>
            <Input
              type="select"
              name="status"
              id="status"
              value={selectedPartner?.status || 'Active'}
              onChange={handleInputChange}
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Input>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave}>Save</Button>{' '}
          <Button color="secondary" onClick={() => toggleModal(editModal ? 'edit' : 'add')}>Cancel</Button>
        </ModalFooter>
      </Modal>

      {/* Delete Modal */}
      <Modal isOpen={deleteModal} toggle={() => toggleModal('delete')}>
        <ModalHeader toggle={() => toggleModal('delete')}>Delete IB</ModalHeader>
        <ModalBody>
          {selectedPartner && (
            <Fragment>
              <p>Are you sure you want to delete {selectedPartner.name}?</p>
            </Fragment>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDelete}>Delete</Button>{' '}
          <Button color="secondary" onClick={() => toggleModal('delete')}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default ReferralPartners;

import React from "react";
import { Col, Container, Row } from "reactstrap";
import  RegistrationForm  from "./studentRegisterForm"

const StudentRegister = () => {
  return (
    <Container className="p-0" fluid>
      <Row className="m-0">
        <Col xs={12} className="p-0">
            <RegistrationForm />
        </Col>
      </Row>
    </Container>
  );
};

export default StudentRegister;

import axios from 'axios';

// Base URL for the API
const API_URL =  process.env.REACT_APP_API_URL ||  "api" 

// Get the Wapikon setup configuration
export const getWapikonSetup = async () => {
  const response = await axios.get(`${API_URL}/wapikon-setup`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

// Create a new Wapikon setup
export const createWapikonSetup = async (setupData) => {
  const response = await axios.post(`${API_URL}/wapikon-setup`, setupData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

// Update a Wapikon setup by ID
export const updateWapikonSetup = async (id, setupData) => {
  const response = await axios.put(`${API_URL}/wapikon-setup/${id}`, setupData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

// Delete a Wapikon setup by ID
export const deleteWapikonSetup = async (id) => {
  const response = await axios.delete(`${API_URL}/wapikon-setup/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

// List all WhatsApp templates
export const listTemplates = async () => {
  const response = await axios.get(`${API_URL}/wapikon-setup/templates`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

// Get the template request body
export const getTemplateBody = async (templateName) => {
  const response = await axios.get(`${API_URL}/wapikon-setup/template-body`, {
    params: { templateName },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

import { Link, useNavigate } from "react-router-dom";
import { Button, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Lock, Mail } from "react-feather";
import { useState } from "react";
import { studentLogin, googleLogin } from "../../Services/authService";
import { forgotPasswordStudent } from "../../Services/forgotPasswordService"; // Import forgotPasswordStudent service
import { Image } from "../../AbstractElements";
import { CreateAccount, EmailAddress, ForgotPassword, Login, Password, RememberPassword, SignIn } from "../../Constant";
import MediaIcons from "../../CommonElements/Media";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode"; 
import { PUBLIC_URL } from "../../Constant";
import { toast } from "react-toastify";

export default function LoginFormStudent() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for forgot password modal
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState(""); // State for forgot password email
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await studentLogin(email, password);
      toast.success("Login Successfully")
      navigate(`/student/profile`);
    } catch (error) {
      toast.error(error.message);
      setErrorMessage("Invalid credentials. Please try again.");
      console.error("Login error:", error);
    }
  };

  const handleGoogleLoginSuccess = async (response) => {
    try {
      const userObject = jwtDecode(response.credential);

      // Perform Google login
      await googleLogin({
        name: userObject.name,
        email: userObject.email,
        googleId: userObject.sub,
        avatar: userObject.picture,
      });

      navigate(`/student/profile`);
    } catch (error) {
      setErrorMessage("Google Login failed. Please try again.");
      console.error("Google Login Error: ", error);
    }
  };

  const handleGoogleLoginFailure = (response) => {
    setErrorMessage("Google Login failed. Please try again.");
    console.error("Google Login Error: ", response);
  };

  const handleForgotPassword = async () => {
    try {
      await forgotPasswordStudent(forgotPasswordEmail);
      toast.success("Password reset instructions sent to your email.");
      setIsModalOpen(false);
    } catch (error) {
      toast.error("Error sending password reset email.");
      console.error("Forgot Password Error: ", error);
    }
  };

  return (
    <GoogleOAuthProvider clientId="YOUR_GOOGLE_CLIENT_ID">
      <div>
        <div className='login-card1'>
          <Form className='theme-form login-form' onSubmit={handleLogin}>
            <div className='login-header text-center'>
              <h4>{Login}</h4>
              <h6>Welcome back, Student! Log in to your student account.</h6>
            </div>

            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

            <FormGroup>
              <Label>{EmailAddress}</Label>
              <InputGroup>
                <InputGroupText>
                  <Mail />
                </InputGroupText>
                <Input
                  type='email'
                  required
                  placeholder='example@student.com'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Label>{Password}</Label>
              <InputGroup>
                <InputGroupText>
                  <Lock />
                </InputGroupText>
                <Input
                  type={isPasswordVisible ? "text" : "password"}
                  name='password'
                  required
                  placeholder='*********'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div className='show-hide' onClick={togglePasswordVisibility}>
                  <span className={!isPasswordVisible ? "show" : ""}></span>
                </div>
              </InputGroup>
            </FormGroup>

            <FormGroup className='login-btn'>
              <div className='checkbox'>
                <Input id='checkbox1' type='checkbox' />
                <Label className='text-muted' for='checkbox1'>
                  {RememberPassword}
                </Label>
              </div>
              <span className='link' onClick={() => setIsModalOpen(true)}>
                {ForgotPassword}?
              </span>
            </FormGroup>
            <FormGroup>
              <Button type="submit" className='btn-block' color='primary'>
                {SignIn}
              </Button>
            </FormGroup>
            <div className='login-social-title'>
              <h5>Or sign in with</h5>
            </div>
            <FormGroup className="text-center d-flex justify-content-center">
              <GoogleLogin
                onSuccess={handleGoogleLoginSuccess}
                onError={handleGoogleLoginFailure}
                text="signin_with"
                width="280"
              />
            </FormGroup>
            <FormGroup>
              <MediaIcons />
            </FormGroup>
            <p>
              Don't have an account?
              <Link to={`/student/register`} className='ms-2'>
                {CreateAccount}
              </Link>
            </p>
          </Form>
        </div>

        {/* Forgot Password Modal */}
        <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)}>
          <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>
            Forgot Password
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="forgotPasswordEmail">Enter your email address:</Label>
              <Input
                type="email"
                id="forgotPasswordEmail"
                value={forgotPasswordEmail}
                onChange={(e) => setForgotPasswordEmail(e.target.value)}
                placeholder="example@student.com"
                required
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleForgotPassword}>
              Send Reset Link
            </Button>{' '}
            <Button color="secondary" onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </GoogleOAuthProvider>
  );
}

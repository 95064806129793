import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Table, Button } from 'reactstrap';
import { H5 } from '../../AbstractElements';
import { getStudentCourses } from '../../Services/StudentPersonal'; // Fetching courses
import { createPaymentOrder } from '../../Services/paymentService'; // For payment processing

const CoursePurchasePage = () => {
    const [courses, setCourses] = useState([]); // Initialize as empty array
    const [error, setError] = useState(null); // To track fetch errors

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const data = await getStudentCourses();

                // Ensure data is an array
                if (Array.isArray(data)) {
                    setCourses(data);
                } else {
                    setCourses([]); // Fallback to an empty array if data is not an array
                    setError('Invalid course data received');
                }
            } catch (error) {
                console.error('Error fetching courses:', error);
                setCourses([]); // Fallback to an empty array on error
                setError('Failed to fetch courses');
            }
        };

        fetchCourses();
    }, []);

    const handleBuyCourse = async (course) => {
        const paymentData = {
            course: course._id,
            amount: course.price,
            MUID: "MUID" + Date.now(),
            transactionId: 'T' + Date.now(),
        };

        try {
            const response = await createPaymentOrder(paymentData);

            if (response && response.data && response.data.instrumentResponse && response.data.instrumentResponse.redirectInfo.url) {
                window.location.href = response.data.instrumentResponse.redirectInfo.url;
            } else {
                alert('Payment failed: No redirect URL found');
            }
        } catch (error) {
            console.error('Payment error:', error);
            alert('Payment error occurred. Please try again later.');
        }
    };

    return (
        <Fragment>
            <Card style={{ width: '100%' }}>
                <CardHeader>
                    <H5>Available Courses</H5>
                </CardHeader>
                <CardBody>
                    {error ? (
                        <p className="text-danger">{error}</p> // Display error message if fetch fails
                    ) : (
                        <Table responsive className="table-responsive">
                            <thead>
                                <tr>
                                    <th scope="col">Course Name</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {courses.length > 0 ? (
                                    courses.map((course, index) => (
                                        <tr key={index}>
                                            <td>{course.name}</td>
                                            <td>{course.price} INR</td>
                                            <td>
                                                <Button color="primary" onClick={() => handleBuyCourse(course)}>
                                                    Enroll Now
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="3" className="text-center">No courses available</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    )}
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default CoursePurchasePage;

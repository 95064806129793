import axios from 'axios';

// Base URL for the API
const API_URL =  process.env.REACT_APP_API_URL ||  "api" 

// Get all IBs
export const getIBs = async () => {
  const response = await axios.get(`${API_URL}/ibs`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

// Get a single IB by ID
export const getIBById = async (id) => {
  const response = await axios.get(`${API_URL}/ibs/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

// Create a new IB
export const createIB = async (ibData) => {
  const response = await axios.post(`${API_URL}/ibs`, ibData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

// Update an IB by ID
export const updateIB = async (id, ibData) => {
  const response = await axios.put(`${API_URL}/ibs/${id}`, ibData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

// Delete an IB by ID
export const deleteIB = async (id) => {
  const response = await axios.delete(`${API_URL}/ibs/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

// Get all students of IB
export const getIBStudents = async () => {
  const response = await axios.get(`${API_URL}/ibPersonal/students`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};
import React, { Fragment } from 'react';
import './App.css';
import Routers from './Routes2';
import AnimationThemeProvider from './_helper/AnimationTheme/AnimationThemeProvider';
import CustomizerProvider from './_helper/customizer/CustomizerProvider';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
      return (
            <Fragment>
                  <CustomizerProvider>
                        <AnimationThemeProvider>
                              <Routers />
                              <ToastContainer />
                        </AnimationThemeProvider>
                  </CustomizerProvider>
            </Fragment >
      );
}
export default App;
import React from 'react'
import CoursePurchasePage from './OnGoing';

function EnrollCoursePage() {
  const val = true;
  return (
    <>
    <CoursePurchasePage val={val}/>
    </>
  )
}

export default EnrollCoursePage;
import React from "react";
import { CardHeader, Col, Container, Row } from "reactstrap";
import LoginForm from "./LoginForm";
import LoginFormStudent from "./LoginFormStudent";

const StudentLogin = () => {
  return (
    <Container className="p-0" fluid>
      <Row className="m-0">
        <Col xs={12} className="p-0">
            <LoginFormStudent />
        </Col>
      </Row>
    </Container>
  );
};

export default StudentLogin;

import React from 'react'
import notfound from '../../assets/notfound.png'
function Error404() {
  return (
    <div className='flex justify-center items-center h-[90vh]'>
          <img src={notfound} alt="ok" />
          <h1>Not Found</h1>
    </div>
  )
}

export default Error404
import React, { Fragment } from 'react';
import { Card, CardBody, CardHeader, Media, Button } from 'reactstrap';
import { H3, H6, P, Image } from '../../AbstractElements';
import { Link } from 'react-router-dom';
import { MyProfile, PUBLIC_URL } from '../../Constant';

const ProfileCard = ({ name, profilePhoto, role, email, isEditing, handleNameEdit }) => {
  return (
    <Fragment>
      <Card>
        <CardHeader className="pb-0 mt-8">
          <H6 attrH6={{ className: 'card-title mb-0' }}>{MyProfile}</H6>
          <div className="card-options">
            <a className="card-options-collapse" href="#javascript">
              <i className="fe fe-chevron-up"></i>
            </a>
            <a className="card-options-remove" href="#javascript">
              <i className="fe fe-x"></i>
            </a>
          </div>
        </CardHeader>
        <CardBody>
          <div className="profile-title">
            <Media>
              <Image
                attrImage={{
                  className: 'img-70 rounded-circle',
                  alt: 'IB Profile',
                  src: profilePhoto || 'https://tse2.mm.bing.net/th?id=OIP.r3amQ5SVFxZSdvz0Q_JGvAHaHw&pid=Api&P=0&h=180'
                }}
              />
              <Media body className="ml-3">
                <H3 attrH3={{ className: 'mb-1 f-20 txt-primary' }}>
                  <Link to={`${PUBLIC_URL}/adminprofile`}>{name}</Link>
                  <H6>{role}</H6>
                </H3>
                <Button color="link" onClick={handleNameEdit}>
                  {isEditing ? 'Save' : 'Edit'}
                </Button>
              </Media>
            </Media>
          </div>
          <div className="profile-details mt-3">
            <H6 attrH6={{ className: 'text-muted' }}>Email:</H6>
            <P className="mb-2">{email || 'No email provided.'}</P>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default ProfileCard;

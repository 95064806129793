import axios from 'axios';

// Base URL for the API
const API_URL =  process.env.REACT_APP_API_URL ||  "api" 

// Submit a form response
export const submitFormResponse = async (formId, formResponseData) => {
    try {
        const response = await axios.post(`${API_URL}/formResponse/${formId}/responses`, formResponseData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error submitting form response:', error);
        throw error;
    }
};

// Get all responses for a form
export const getFormResponses = async (formId) => {
    try {
        const response = await axios.get(`${API_URL}/formResponse/${formId}/responses`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching form responses:', error);
        throw error;
    }
};

// Get a single response by ID
export const getFormResponseById = async (responseId) => {
    try {
        const response = await axios.get(`${API_URL}/formResponse/responses/${responseId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error(`Error fetching form response with ID ${responseId}:`, error);
        throw error;
    }
};

// Delete a form response
export const deleteFormResponse = async (responseId) => {
    try {
        const response = await axios.delete(`${API_URL}/formResponse/responses/${responseId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error(`Error deleting form response with ID ${responseId}:`, error);
        throw error;
    }
};

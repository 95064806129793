import React from 'react'
import OnGoing from './OnGoing';

function AddBiPage() {
  const val = true;
  return (
    <>
    <OnGoing val={val}/>
    </>
  )
}

export default AddBiPage;
import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import AccessControlPage from '../../Component/AccessControl';

const AccessControl = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Pages" title="Access-control" />
      <AccessControlPage />
    </Fragment>
  );
};
export default AccessControl;
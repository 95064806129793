import { Link, useNavigate } from "react-router-dom";
import { Button, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Lock, Mail } from "react-feather";
import { Image } from "../../AbstractElements";
import { CreateAccount, EmailAddress, ForgotPassword, IB_URL, Login, Password, RememberPassword, SignIn } from "../../Constant";
import MediaIcons from "../../CommonElements/Media";
import { useState } from "react";
import { IBlogin } from "../../Services/authService";  // Import the authService
import { forgotPasswordIB } from "../../Services/forgotPasswordService";  // Import the authService
import { PUBLIC_URL } from "../../Constant";

export default function LoginForm() {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false); // State for modal
  const [forgotEmail, setForgotEmail] = useState(''); // State for forgot password email
  const [forgotPasswordError, setForgotPasswordError] = useState(null); // Error state for forgot password
  const [successMessage, setSuccessMessage] = useState(null); // Success message state
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const data = await IBlogin(email, password);
      // Store the token in localStorage or context
      localStorage.setItem('token', data.token);
      navigate('/ib/add-students');  // Navigate to the dashboard on success
    } catch (err) {
      setError('Invalid credentials, please try again.');
    }
  };

  const handleForgotPasswordSubmit = async () => {
    try {
      const data = await forgotPasswordIB(forgotEmail);
      setSuccessMessage('Password reset link sent to your email.');
      setForgotPasswordError(null);
    } catch (err) {
      setForgotPasswordError('Error sending password reset email.');
      setSuccessMessage(null);
    }
  };

  var images = require.context("../../assets/images", true);
  const dynamicImage = (image) => {
    return images(`./${image}`);
  };

  return (
    <div>
      <div>
        <Link className='logo' to={`${PUBLIC_URL}/dashboard/default`}>
          <Image className='img-fluid for-light' src={dynamicImage(`logo/logo.svg`)} alt='looginpage' body={true} />
        </Link>
      </div>
      <div className='login-card1'>
        <Form className='theme-form login-form' onSubmit={handleLogin}>
          <div className='login-header text-center'>
            <h4>{Login}</h4>
            <h6>Welcome back IB! Log in to your account.</h6>
          </div>

          {error && <div className="alert alert-danger">{error}</div>}

          <FormGroup>
            <Label>{EmailAddress}</Label>
            <InputGroup>
              <InputGroupText>
                <Mail />
              </InputGroupText>
              <Input
                type='email'
                required
                placeholder='Test@gmail.com'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label>{Password}</Label>
            <InputGroup>
              <InputGroupText>
                <Lock />
              </InputGroupText>
              <Input
                type={isPasswordVisible ? "text" : "password"}
                name='login[password]'
                required
                placeholder='*********'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className='show-hide' onClick={togglePasswordVisibility}>
                <span className={!isPasswordVisible ? "show" : ""}></span>
              </div>
            </InputGroup>
          </FormGroup>

          <FormGroup className='login-btn'>
            <div className='checkbox'>
              <Input id='checkbox1' type='checkbox' />
              <Label className='text-muted' for='checkbox1'>
                {RememberPassword}
              </Label>
            </div>
            <Link onClick={() => setForgotPasswordModal(true)} className='link'>
              {ForgotPassword}?
            </Link>
          </FormGroup>
          <FormGroup>
            <Button type='submit' className='btn-block' color='primary'>
              {SignIn}
            </Button>
          </FormGroup>
          <div className='login-social-title'>
            <h5>Sign in with Email</h5>
          </div>
          <FormGroup>
            <MediaIcons />
          </FormGroup>
          <p>
            Don't have an account?
            <Link to={`/${IB_URL}/register`} className='ms-2'>
              {CreateAccount}
            </Link>
          </p>
        </Form>
      </div>

      {/* Forgot Password Modal */}
      <Modal isOpen={forgotPasswordModal} toggle={() => setForgotPasswordModal(!forgotPasswordModal)}>
        <ModalHeader toggle={() => setForgotPasswordModal(!forgotPasswordModal)}>Forgot Password</ModalHeader>
        <ModalBody>
          {forgotPasswordError && <div className="alert alert-danger">{forgotPasswordError}</div>}
          {successMessage && <div className="alert alert-success">{successMessage}</div>}
          <FormGroup>
            <Label for="forgotEmail">{EmailAddress}</Label>
            <Input
              type='email'
              id='forgotEmail'
              value={forgotEmail}
              onChange={(e) => setForgotEmail(e.target.value)}
              placeholder='Enter your email address'
              required
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={handleForgotPasswordSubmit}>
            Send Reset Link
          </Button>{' '}
          <Button color='secondary' onClick={() => setForgotPasswordModal(!forgotPasswordModal)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import DashBoardPage from '../../Component/DashBoard';

const DashBoard = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Pages" title="dashboard" />
      <DashBoardPage />
    </Fragment>
  );
};
export default DashBoard;
import React from 'react'
import OnGoing from './OnGoing';

function TradingPage() {
  const val = true;
  return (
    <>
    <OnGoing val={val}/>
    </>
  )
}

export default TradingPage;
import React from 'react'
import ViewForm from './OnGoing';

function ViewFormPage() {
  const val = true;
  return (
    <>
    <ViewForm val={val}/>
    </>
  )
}

export default ViewFormPage;
import React, { Fragment } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import TradingPage from '../../../Component/TradingPage';

const TradingAccountPage = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Users" title="Trading Account" />
      <TradingPage />
    </Fragment>
  );
};
export default TradingAccountPage;
import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Table, Button, Spinner } from 'reactstrap';
import { H5 } from '../../AbstractElements';
import { IoMdPersonAdd } from "react-icons/io";
import { getAllBrokers } from '../../Services/brokerServices'; // Importing broker service

const TradingPage = () => {
  const [loading, setLoading] = useState(true);
  const [brokers, setBrokers] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchBrokers();
  }, []);

  const fetchBrokers = async () => {
    try {
      const brokers = await getAllBrokers();
      console.log(brokers);
      setBrokers(brokers);

      setLoading(false);
    } catch (error) {
      setError('Error fetching brokers');
      setLoading(false);
    }
  };

  return (
    <Fragment>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner color="primary" />
        </div>
      ) : error ? (
        <div className="text-center text-danger">{error}</div>
      ) : (
        <Fragment>
          {/* Indian Brokers */}
          <Card style={{ width: '100%' }}>
            <CardHeader>
              <div className="d-flex flex-wrap gap-1 justify-content-between align-items-center">
                <H5>Brokers</H5>
              </div>
            </CardHeader>
            <CardBody>
              <Table responsive className="table-responsive">
                <thead>
                  <tr>
                    <th scope="col">Broker Name</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {brokers.map((broker) => (
                    <tr key={broker._id}>
                      <td>{broker.name}</td>
                      <td>
                        <Button color="primary" onClick={() => window.open(broker.link, '_blank')}>
                          Open Account
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Fragment>
      )}
    </Fragment>
  );
};

export default TradingPage;

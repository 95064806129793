import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import StudyMaterialPage from '../../Component/StudyMaterial';

const UpcomingPage = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Pages" title="" />
            <h1>Upcoming Feature </h1>
        </Fragment>
    );
};
export default UpcomingPage;
import React, { Fragment, Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
// import { Auth0Provider } from '@auth0/auth0-react';
// import { firebase_app, auth0 } from '../Config/Config';
// import { configureFakeBackend, authHeader, handleResponse, } from '../Services/Fack.Backend';
import Loader from '../Layout/Loader';
import LayoutRoutes from './LayoutRoutes';
import Callback from '../Auth/Callback';
// import PrivateRoute from './PrivateRoute';
// import Signin from '../Auth/Signin';
import { ADMIN_URL, STUDENT_URL, IB_URL } from '../Constant';
import AdminLogin from '../Pages/Auth/AdminLogin';
import StudentLogin from '../Pages/Auth/StudentLogin';
import StudentRegister from '../Pages/StudentRegister';
import ViewFormPage from '../Pages/ViewForm';
import IBPage from '../Pages/IB';
import IBLoginPage from '../Pages/IBLoginPage';
import IBRegisterPage from '../Pages/IBRegisterPage';
// configureFakeBackend();
const Routers = () => {
  // const [currentUser, setCurrentUser] = useState(false);
  // const [authenticated, setAuthenticated] = useState(false);
  // const jwt_token = localStorage.getItem('token');
  // useEffect(() => {
  //   let abortController = new AbortController();
  //   const requestOptions = { method: 'GET', headers: authHeader() };
  //   fetch('/users', requestOptions).then(handleResponse);
  //   firebase_app.auth().onAuthStateChanged(setCurrentUser);
  //   setAuthenticated(JSON.parse(localStorage.getItem('authenticated')));
  //   console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
  //   console.disableYellowBox = true;
  //   return () => {
  //     abortController.abort();
  //   };
  // }, []);
  var admin = true;
  return (
    <Fragment>
      {/* <Auth0Provider domain={auth0.domain} clientId={auth0.clientId} redirectUri={auth0.redirectUri}> */}
      <BrowserRouter basename={'/'}>
        <>
          <Suspense fallback={<Loader />}>
            <Routes>
              {/* <Route path={'/'} element={<PrivateRoute />}> */}
              {/* {currentUser !== null || authenticated || jwt_token ? */}
              {console.log("current url",)}
              <>
                {
                  admin ?
                    <Route exact
                      path={`/`}
                      element={<Navigate to={`/adminLogin`} />}
                    />
                    :
                    <Route exact
                      path={`/`}
                      element={<Navigate to={`${STUDENT_URL}/login`} />}
                    />
                }
              </>
              <Route path={`/*`} element={<LayoutRoutes userType='ib' />} />
              {/* </Route> */}
              <Route path={`${ADMIN_URL}/callback`} render={() => <Callback />} />
              <Route exact path={`/form/:id`} element={<ViewFormPage />} />
              <Route exact path={`${IB_URL}/login`} element={<IBLoginPage />} />
              <Route exact path={`${IB_URL}/register`} element={<IBRegisterPage />} />
              <Route exact path={`${ADMIN_URL}/adminlogin`} element={<AdminLogin />} />
              <Route exact path={`${STUDENT_URL}/login`} element={<StudentLogin />} />
              <Route exact path={`${STUDENT_URL}/register`} element={<StudentRegister />} />
            </Routes>
          </Suspense>
        </>
      </BrowserRouter>
      {/* </Auth0Provider> */}
    </Fragment >
  );
};
export default Routers;
import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Form, FormGroup, Input, Label, Media, Row } from 'reactstrap';
import { Btn, H3, H4, H6, Image, P } from '../../AbstractElements';
import { MyProfile, Bio, Password, Website, Save, EmailAddress, PUBLIC_URL } from '../../Constant';
import { Link } from 'react-router-dom';
import { getStudentProfile, updateStudentProfile } from '../../Services/StudentPersonal';

const MyProfileEdit = () => {
  const [profile, setProfile] = useState({
    name: '',
    bio: '',
    email: '',
    password: '',
    website: '',
    profilePicture: '',
    idCard: '', // Added field for ID Card
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    try {
      const data = await getStudentProfile();
      setProfile({
        name: data.name,
        bio: data.bio,
        email: data.email,
        password: '', // Password should not be fetched for security reasons
        website: data.website || '',
        profilePicture: data.profilePicture || `https://icon-library.com/images/user-png-icon/user-png-icon-16.jpg`,
        idCard: data.idCard || '', // Fetching ID card data
      });
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const handleSave = async () => {
    try {
      await updateStudentProfile(profile);
      alert('Profile updated successfully!');
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Failed to update profile.');
    }
  };

  const handleDownloadIdCard = () => {
    if (profile.idCard) {
      const link = document.createElement('a');
      link.href = profile.idCard;
      link.download = `${profile.name}-IDCard.pdf`;
      link.click();
    } else {
      alert('ID Card is not available.');
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Fragment>
      <Card>
        <CardHeader className="pb-0">
          <H4 attrH4={{ className: 'card-title mb-0' }}>{MyProfile}</H4>
          <div className="card-options">
            <a className="card-options-collapse" href="#javascript">
              <i className="fe fe-chevron-up"></i>
            </a>
            <a className="card-options-remove" href="#javascript">
              <i className="fe fe-x"></i>
            </a>
          </div>
        </CardHeader>
        <CardBody>
          <Form>
            <Row className="mb-2">
              <div className="profile-title">
                <Media>
                  <Image attrImage={{ className: 'img-70 rounded-circle', alt: '', src: profile.profilePicture }} />
                  <Media body>
                    <H3 attrH3={{ className: 'mb-1 f-20 txt-primary' }}>
                      <Link to={`${PUBLIC_URL}/student/userprofile`}>{profile.name}</Link>
                    </H3>
                    <P>DESIGNER</P>
                  </Media>
                </Media>
              </div>
            </Row>
            <FormGroup className="mb-3">
              <H6 attrH6={{ className: 'form-label' }}>{Bio}</H6>
              <Input
                type="textarea"
                className="form-control"
                rows="5"
                name="bio"
                value={profile.bio}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label className="form-label">{EmailAddress}</Label>
              <Input
                type="email"
                className="form-control"
                name="email"
                placeholder="your-email@domain.com"
                value={profile.email}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label className="form-label">{Password}</Label>
              <Input
                type="password"
                className="form-control"
                name="password"
                placeholder="*********"
                value={profile.password}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label className="form-label">{Website}</Label>
              <Input
                type="text"
                className="form-control"
                name="website"
                placeholder="http://example.com"
                value={profile.website}
                onChange={handleInputChange}
              />
            </FormGroup>
            <div className="form-footer">
              <Btn attrBtn={{ className: 'btn-block', color: 'primary', type: 'button', onClick: handleSave }}>{Save}</Btn>
            </div>
            <div className="form-footer mt-3">
              <Btn attrBtn={{ className: 'btn-block', color: 'secondary', type: 'button', onClick: handleDownloadIdCard }}>
                Download ID Card
              </Btn>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default MyProfileEdit;

import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import AddBrokers from '../../Component/AddBroker';

const AddBrokerPage = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Pages" title="Add-Events" />
      <AddBrokers />
    </Fragment>
  );
};
export default AddBrokerPage;
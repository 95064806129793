import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import Chart from 'react-apexcharts';
import { getActiveCounts } from '../../../Services/studentService'; // Ensure the correct import path

const DonutChartClass = () => {
  // Initialize state with default values
  const [chartData, setChartData] = useState({
    series: [0, 0], // Default values for active and inactive
    options: {
      chart: {
        type: 'donut',
      },
      labels: ['Active', 'Inactive'],
      colors: ['#28a745', '#dc3545'], // Green for active, red for inactive
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200 // Adjusted width for smaller screens
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
      legend: {
        position: 'right',
        markers: {
          fillColors: ['#28a745', '#dc3545'] // Ensures the legend has the same colors
        }
      }
    }
  });

  useEffect(() => {
    const fetchActiveCounts = async () => {
      try {
        const { active, inactive } = await getActiveCounts();
        console.log('Fetched Active Counts:', { active, inactive }); // Log the fetched counts
        setChartData(prevState => ({
          ...prevState,
          series: [active, inactive]
        }));
      } catch (error) {
        console.error('Error fetching active counts:', error);
      }
    };

    fetchActiveCounts();
  }, []);

  return (
    <Fragment>
      <Col xs='12' sm='12' md='4' lg='4' xl='4' className='box-col-6'>
        <Card style={{ padding: '10px', margin: '10px' }}> {/* Adjust padding and margin */}
          <CardHeader className='text-center' style={{ fontSize: '20px', fontWeight: 'bold' }}> {/* Adjusted font size */}
            Active/Inactive
          </CardHeader>
          <CardBody>
            <div id='donutchart' style={{ width: '100%', height: '200px' }}> {/* Adjusted height */}
              <Chart options={chartData.options} series={chartData.series} type="donut" width='100%' height="200px" /> {/* Adjusted height */}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default DonutChartClass;

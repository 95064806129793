import React, { Fragment, useState, useEffect } from 'react';
import {
  Card, CardBody, CardHeader, Input, Media, Button, Modal,
  ModalHeader, ModalBody, ModalFooter, FormGroup, Label
} from 'reactstrap';
import { H5, H6 } from '../../AbstractElements';
import { IoMdSettings } from "react-icons/io";
import { LuRefreshCcw } from "react-icons/lu";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { getAllMailSetups, updateMailSetup, createMailSetup } from '../../Services/mailService';

const SmtpConfiguration = () => {
  const [smtpSettings, setSmtpSettings] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [currentSettings, setCurrentSettings] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [isNewConfig, setIsNewConfig] = useState(false); // Track if it's a new configuration

  useEffect(() => {
    fetchSmtpSettings();
  }, []);

  const fetchSmtpSettings = async () => {
    try {
      const settings = await getAllMailSetups();
      if (settings && settings.length > 0) {
        setSmtpSettings(settings[0]);
        setCurrentSettings(settings[0]);
        setIsNewConfig(false); // Existing configuration
      } else {
        setSmtpSettings(null);
        setCurrentSettings({});
        setIsNewConfig(true); // No configuration present, allow creating a new one
      }
    } catch (error) {
      console.error('Error fetching SMTP settings:', error);
    }
  };

  const toggleModal = () => {
    setEditModal(!editModal);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentSettings({ ...currentSettings, [name]: value });
  };

  const handleSave = async () => {
    try {
      if (isNewConfig) {
        await createMailSetup(currentSettings);
      } else {
        await updateMailSetup(currentSettings._id, currentSettings);
      }
      fetchSmtpSettings(); // Refresh the settings after save
      toggleModal();
    } catch (error) {
      console.error('Error saving SMTP settings:', error);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Fragment>
      <Card style={{ width: '95%', margin: '0 auto', paddingRight: '5px' }} className="dash-35">
        <Card className='smtp-settings'>
          <CardHeader className="card-no-border">
            <Media className="media-dashboard">
              <Media body>
                <H5 attrH5={{ className: 'mb-0' }}>SMTP Configuration</H5>
              </Media>
            </Media>
          </CardHeader>
          <CardBody>
            <div className="d-flex flex-wrap justify-content-end gap-2 mb-3">
              <Button color="primary" onClick={toggleModal}>
                <IoMdSettings /> {smtpSettings ? 'Edit SMTP Settings' : 'Add SMTP Settings'}
              </Button>
              <Button outline color="primary" onClick={fetchSmtpSettings}>
                <LuRefreshCcw /> Refresh
              </Button>
            </div>
            {smtpSettings ? (
              <div>
                <H6>SMTP Host: {smtpSettings.smtpHost}</H6>
                <H6>SMTP Port: {smtpSettings.smtpPort}</H6>
                <H6>Username: {smtpSettings.email}</H6>
                <H6>From Name: {smtpSettings.fromName}</H6>
                <H6>From Email: {smtpSettings.fromEmail}</H6>
                <H6>Encryption: {smtpSettings.encryption}</H6>
              </div>
            ) : (
              <div>
                <H6>No SMTP configuration found. Please add a new configuration.</H6>
              </div>
            )}
          </CardBody>
        </Card>
      </Card>

      {/* Edit Modal */}
      <Modal isOpen={editModal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{isNewConfig ? 'Add SMTP Settings' : 'Edit SMTP Settings'}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="smtpHost">SMTP Host</Label>
            <Input
              type="text"
              name="smtpHost"
              id="smtpHost"
              value={currentSettings.smtpHost || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="smtpPort">SMTP Port</Label>
            <Input
              type="number"
              name="smtpPort"
              id="smtpPort"
              value={currentSettings.smtpPort || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">Username</Label>
            <Input
              type="text"
              name="email"
              id="email"
              value={currentSettings.email || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="password">Password</Label>
            <div style={{ position: 'relative' }}>
              <Input
                type={showPassword ? "text" : "password"}
                name="password"
                id="password"
                value={currentSettings.password || ''}
                onChange={handleInputChange}
              />
              <Button
                style={{ position: 'absolute', right: 0, top: 0, height: '100%', border: 'none', background: 'none' }}
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </Button>
            </div>
          </FormGroup>
          <FormGroup>
            <Label for="fromName">From Name</Label>
            <Input
              type="text"
              name="fromName"
              id="fromName"
              value={currentSettings.fromName || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="fromEmail">From Email</Label>
            <Input
              type="email"
              name="fromEmail"
              id="fromEmail"
              value={currentSettings.fromEmail || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="encryption">Encryption</Label>
            <Input
              type="select"
              name="encryption"
              id="encryption"
              value={currentSettings.encryption || 'None'}
              onChange={handleInputChange}
            >
              <option value="TLS">TLS</option>
              <option value="SSL">SSL</option>
              <option value="None">None</option>
            </Input>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave}>Save</Button>{' '}
          <Button color="secondary" onClick={toggleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default SmtpConfiguration;

import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { H5, P } from '../../../AbstractElements';
import { getAllEvents } from '../../../Services/eventService'; // Import the event service
import moment from 'moment';
import './UpcomingEventsContain.css'; // Assuming you save the CSS in the same folder

const UpcomingEventsContain = () => {
  const [events, setEvents] = useState([]); // Initialize as an empty array

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const data = await getAllEvents();

        // Ensure data is an array before setting it to state
        if (Array.isArray(data)) {
          setEvents(data);
        } else {
          setEvents([]); // Fallback to empty array if the response is not an array
        }
      } catch (error) {
        console.error('Error fetching events:', error);
        setEvents([]); // Fallback to empty array in case of an error
      }
    };

    fetchEvents();
  }, []);

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            {events.length > 0 ? (
              events.map((event) => (
                <Card key={event._id} className="event-card">
                  <CardHeader className="event-card-header">
                    <H5 className="event-card-title">{event.name}</H5>
                    <span className="event-card-date">
                      {moment(event.date).format('MMMM Do, YYYY')}
                    </span>
                  </CardHeader>
                  <CardBody className="event-card-body">
                    <P><strong>Description:</strong> {event.description}</P>
                    <P><strong>Location:</strong> {event.location}</P>
                    {event.image && (
                      <div className="event-image">
                        <img src={event.image} alt={event.name} style={{ width: '100%', height: 'auto' }} />
                      </div>
                    )}
                  </CardBody>
                </Card>
              ))
            ) : (
              <P>No upcoming events available.</P> // Fallback message for empty events
            )}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default UpcomingEventsContain;

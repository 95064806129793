import React, { Fragment, useState, useEffect } from 'react';
import {
  Card, CardBody, CardHeader, Input, Media, Table, Button, Modal,
  ModalHeader, ModalBody, ModalFooter, FormGroup, Label
} from 'reactstrap';
import { H5, H6 } from '../../AbstractElements';
import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";
import { LuRefreshCcw } from "react-icons/lu";
import {
  getAllEvents, createEvent, updateEvent, deleteEvent
} from '../../Services/eventService';  // Update to event service
import { toast } from 'react-toastify';

const ManageEvents = () => {
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [events, setEvents] = useState([]); // Initialize as an empty array
  const [searchTerm, setSearchTerm] = useState('');
  const [imagePreview, setImagePreview] = useState(''); // For previewing the selected image
  const [selectedImageFile, setSelectedImageFile] = useState(null); // Store the selected image file

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const data = await getAllEvents();

      // Ensure data is an array before setting it to state
      if (Array.isArray(data)) {
        setEvents(data);
      } else {
        setEvents([]); // Set as an empty array if the response is not an array
      }
    } catch (error) {
      console.error('Error fetching events:', error);
      setEvents([]); // Fallback to empty array in case of an error
    }
  };

  const toggleModal = (modalType) => {
    switch (modalType) {
      case 'add':
        setAddModal(!addModal);
        setSelectedEvent(null);
        setImagePreview(''); // Clear image preview
        setSelectedImageFile(null); // Clear image file
        break;
      case 'edit':
        setEditModal(!editModal);
        break;
      case 'delete':
        setDeleteModal(!deleteModal);
        break;
      default:
        break;
    }
  };

  const handleEditClick = (event) => {
    setSelectedEvent(event);
    setImagePreview(event.image || ''); // Set existing image preview if available
    toggleModal('edit');
  };

  const handleDeleteClick = (event) => {
    setSelectedEvent(event);
    toggleModal('delete');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedEvent({ ...selectedEvent, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImageFile(file); // Store the selected image file
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result); // Preview the image as Base64 string
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    try {
      // Add image to the event data if a new image is selected
      if (selectedImageFile) {
        selectedEvent.image = imagePreview; // Save the Base64 string or URL of the image
      }

      if (selectedEvent._id) {
        await updateEvent(selectedEvent._id, selectedEvent);
        toast.success("Event updated successfully");
      } else {
        await createEvent(selectedEvent);
        toast.success("Event created successfully");
      }
      fetchEvents(); // Refresh events list after saving
      toggleModal('edit');
    } catch (error) {
      console.error('Error saving event:', error);
      toast.error('Error saving event');
    }
  };

  const handleDelete = async () => {
    try {
      await deleteEvent(selectedEvent._id);
      fetchEvents(); // Refresh events list after deletion
      toast.success("Event deleted successfully");
      toggleModal('delete');
    } catch (error) {
      console.error('Error deleting event:', error);
      toast.error('Error deleting event');
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Ensure events is an array before filtering
  const filteredEvents = Array.isArray(events) ? events.filter(event =>
    event.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    event.location.toLowerCase().includes(searchTerm.toLowerCase())
  ) : [];

  return (
    <Fragment>
      <Card style={{ width: '95%', margin: '0 auto', paddingRight: '5px' }} className="dash-35">
        <Card className='ongoing-project'>
          <CardHeader className="card-no-border">
            <Media className="media-dashboard">
              <Media body>
                <H5 attrH5={{ className: 'mb-0' }}>Upcoming Events</H5>
              </Media>
            </Media>
          </CardHeader>
          <div className="d-flex flex-wrap justify-content-end align-items-center gap-2 mb-3">
            <div className="d-flex flex-wrap gap-2">
              <Button outline color="primary" onClick={fetchEvents}>
                <LuRefreshCcw />
              </Button>
              <Button color="primary" onClick={() => toggleModal('add')}>
                <FaPlus /> Add Event
              </Button>
            </div>
            <Input
              type="text"
              style={{ maxWidth: '300px' }}
              placeholder="Search events..."
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>

          <CardBody className="pt-0">
            <Table className="table-bordernone" responsive>
              <thead>
                <tr>
                  <th><H5>ID</H5></th>
                  <th><H5>Name</H5></th>
                  <th><H5>Date</H5></th>
                  <th><H5>Location</H5></th>
                  <th><H5>Actions</H5></th>
                </tr>
              </thead>
              <tbody>
                {
                  filteredEvents.map((event) => (
                    <tr key={event._id}>
                      <td><H6>{event._id}</H6></td>
                      <td><H6>{event.name}</H6></td>
                      <td><H6>{new Date(event.date).toLocaleDateString()}</H6></td>
                      <td><H6>{event.location}</H6></td>
                      <td>
                        <div className="d-flex flex-column flex-md-row justify-content-center gap-2">
                          <Button color="primary" onClick={() => handleEditClick(event)}><FaEdit /></Button>
                          <Button color="danger" onClick={() => handleDeleteClick(event)}><FaTrash /></Button>
                        </div>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Card>

      {/* Add/Edit Modal */}
      <Modal isOpen={addModal || editModal} toggle={() => toggleModal(editModal ? 'edit' : 'add')}>
        <ModalHeader toggle={() => toggleModal(editModal ? 'edit' : 'add')}>
          {editModal ? 'Edit Event' : 'Add Event'}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="name">Event Name</Label>
            <Input
              type="text"
              name="name"
              id="name"
              value={selectedEvent?.name || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="date">Event Date</Label>
            <Input
              type="date"
              name="date"
              id="date"
              value={selectedEvent?.date ? new Date(selectedEvent.date).toISOString().split('T')[0] : ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="location">Event Location</Label>
            <Input
              type="text"
              name="location"
              id="location"
              value={selectedEvent?.location || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="description">Event Description</Label>
            <Input
              type="textarea"
              name="description"
              id="description"
              value={selectedEvent?.description || ''}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="image">Event Image</Label>
            <Input
              type="file"
              name="image"
              id="image"
              onChange={handleImageChange} // Handle image selection
            />
            {imagePreview && (
              <div className="mt-2">
                <img src={imagePreview} alt="Event" style={{ width: '100%', height: 'auto' }} />
              </div>
            )}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave}>Save</Button>{' '}
          <Button color="secondary" onClick={() => toggleModal(editModal ? 'edit' : 'add')}>Cancel</Button>
        </ModalFooter>
      </Modal>

      {/* Delete Modal */}
      <Modal isOpen={deleteModal} toggle={() => toggleModal('delete')}>
        <ModalHeader toggle={() => toggleModal('delete')}>Delete Event</ModalHeader>
        <ModalBody>
          {selectedEvent && (
            <Fragment>
              <p>Are you sure you want to delete {selectedEvent.name}?</p>
            </Fragment>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDelete}>Delete</Button>{' '}
          <Button color="secondary" onClick={() => toggleModal('delete')}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default ManageEvents;

import axios from 'axios';

// Base URL for the API
const API_URL =  process.env.REACT_APP_API_URL ||  "api" 

// Get all study materials
export const getStudyMaterials = async () => {
  const response = await axios.get(`${API_URL}/study-materials`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

// Get a study material by ID
export const getStudyMaterialById = async (id) => {
  const response = await axios.get(`${API_URL}/study-materials/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

// Create a new study material
export const createStudyMaterial = async (materialData) => {
  const response = await axios.post(`${API_URL}/study-materials`, materialData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

// Update a study material by ID
export const updateStudyMaterial = async (id, materialData) => {
  const response = await axios.put(`${API_URL}/study-materials/${id}`, materialData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

// Delete a study material by ID
export const deleteStudyMaterial = async (id) => {
  const response = await axios.delete(`${API_URL}/study-materials/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import StudyMaterialPage from '../../Component/StudyMaterial';

const StudyMaterial = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Pages" title="study-material" />
      <StudyMaterialPage />
    </Fragment>
  );
};
export default StudyMaterial;
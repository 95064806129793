import axios from 'axios';

// Base URL for the API
const API_URL =  process.env.REACT_APP_API_URL ||  "api" 

// Get all triggers
export const getTriggers = async () => {
  try {
    const response = await axios.get(`${API_URL}/triggers`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching triggers:', error);
    throw error;
  }
};

// Create a new trigger
export const createTrigger = async (triggerData) => {
  try {
    const response = await axios.post(`${API_URL}/triggers`, triggerData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating trigger:', error);
    throw error;
  }
};

// Update an existing trigger by ID
export const updateTrigger = async (id, triggerData) => {
  try {
    const response = await axios.put(`${API_URL}/triggers/${id}`, triggerData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating trigger:', error);
    throw error;
  }
};

// Delete a trigger by ID
export const deleteTrigger = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/triggers/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting trigger:', error);
    throw error;
  }
};

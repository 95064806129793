import axios from 'axios';

// Base URL for the API
const API_URL =  process.env.REACT_APP_API_URL ||  "api" 

// Forgot Password for Admin
export const forgotPasswordAdmin = async (email) => {
    try {
        const response = await axios.post(`${API_URL}/auth/forgot-password/admin`, { email });
        return response.data;
    } catch (error) {
        console.error('Error in forgot password (Admin):', error);
        throw error;
    }
};

// Forgot Password for Student
export const forgotPasswordStudent = async (email) => {
    try {
        const response = await axios.post(`${API_URL}/auth/forgot-password/student`, { email });
        return response.data;
    } catch (error) {
        console.error('Error in forgot password (Student):', error);
        throw error;
    }
};

// Forgot Password for IB
export const forgotPasswordIB = async (email) => {
    try {
        const response = await axios.post(`${API_URL}/auth/forgot-password/ib`, { email });
        return response.data;
    } catch (error) {
        console.error('Error in forgot password (IB):', error);
        throw error;
    }
};

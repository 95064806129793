import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import BatchPage from '../../Component/Batch';

const CreateBatch = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Pages" title="create-batch" />
      <BatchPage />
    </Fragment>
  );
};
export default CreateBatch;
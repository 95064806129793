import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import CreateFormpage from '../../Component/Form';

const createForm = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Pages" title="Form" />
      <CreateFormpage />
    </Fragment>
  );
};
export default createForm;
import React from 'react';
import PieChartClass from '../chart/ApexChart/PieChart';
import MixedChartClass from '../chart/ApexChart/MixedChart';
import DonutChartClass from '../chart/ApexChart/DonutChart';
import './DashboardPage.css'; // Import the CSS file for styling

function DashBoardPage() {
  return (
    <div className="dashboard-container">
      <div className="chart-container">
        <PieChartClass />
        <DonutChartClass />
      </div>
      <MixedChartClass />
    </div>
  );
}

export default DashBoardPage;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getFormById } from "../../Services/formService";
import { submitFormResponse } from "../../Services/formResponseService";
import { Btn } from '../../AbstractElements';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './FormPage.css';

const FormPage = () => {
  const { id } = useParams();
  const [form, setForm] = useState(null);
  const [formData, setFormData] = useState({ submittedBy: '', phone: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formStatus, setFormStatus] = useState('loading');

  useEffect(() => {
    getFormById(id)
      .then(response => {
        const today = new Date();
        const isExpired = response.expiryDate && new Date(response.expiryDate) < today;
        const isInactive = response.status !== 'active';

        if (isExpired) {
          setFormStatus('expired');
        } else if (isInactive) {
          setFormStatus('inactive');
        } else {
          setForm(response);
          setFormStatus('valid');
        }
      })
      .catch(error => {
        console.error('Error fetching the form:', error);
        setFormStatus('error');
      });
  }, [id]);

  const handleInputChange = (fieldId, value) => {
    setFormData({
      ...formData,
      [fieldId]: value,
    });
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      phone: value,
    });
  };

  const handleSubmit = async () => {
    if (!formData.submittedBy) {
      alert('Email is required.');
      return;
    }

    if (!formData.phone || formData.phone.length < 10) {
      alert('Please enter a valid phone number.');
      return;
    }

    setIsSubmitting(true);

    const formDataToSend = new FormData();

    const responses = form.fields.map(field => {
      let response;
      if (field.type === 'file') {
        response = formData[field.id]?.name;
        formDataToSend.append(field.label, formData[field.id]);
      } else {
        response = formData[field.id] || '';
      }
      return {
        fieldLabel: field.label,
        fieldType: field.type,
        response,
      };
    });

    formDataToSend.append('responses', JSON.stringify(responses));
    formDataToSend.append('submittedBy', formData.submittedBy);
    formDataToSend.append('phone', formData.phone);

    try {
      await submitFormResponse(id, formDataToSend);
      alert('Form submitted successfully!');
    } catch (error) {
      console.error('Error submitting form response:', error);
      alert('Failed to submit the form.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderFormFields = () => {
    return form.fields.map((field) => {
      switch (field.type) {
        case 'input':
          return (
            <div key={field.id} className="form-group">
              <label>{field.label}</label>
              <input
                type="text"
                placeholder={field.placeholder}
                value={formData[field.id] || ''}
                onChange={(e) => handleInputChange(field.id, e.target.value)}
                className="form-control"
              />
            </div>
          );
        case 'textarea':
          return (
            <div key={field.id} className="form-group">
              <label>{field.label}</label>
              <textarea
                placeholder={field.placeholder}
                value={formData[field.id] || ''}
                onChange={(e) => handleInputChange(field.id, e.target.value)}
                className="form-control"
              />
            </div>
          );
        case 'dropdown':
          return (
            <div key={field.id} className="form-group">
              <label>{field.label}</label>
              <select
                value={formData[field.id] || ''}
                onChange={(e) => handleInputChange(field.id, e.target.value)}
                className="form-control"
              >
                <option value="" disabled>Select an option</option>
                {field.options.map((option, index) => (
                  <option key={index} value={option}>{option}</option>
                ))}
              </select>
            </div>
          );
        case 'checkbox':
          return (
            <div key={field.id} className="form-group">
              <label>{field.label}</label>
              {field.options.map((option, index) => (
                <div key={index} className="form-check">
                  <input
                    type="checkbox"
                    value={option}
                    checked={formData[field.id]?.includes(option) || false}
                    onChange={(e) => {
                      const checkedOptions = formData[field.id] || [];
                      if (e.target.checked) {
                        handleInputChange(field.id, [...checkedOptions, option]);
                      } else {
                        handleInputChange(field.id, checkedOptions.filter(o => o !== option));
                      }
                    }}
                    className="form-check-input"
                  />
                  <label className="form-check-label">{option}</label>
                </div>
              ))}
            </div>
          );
        case 'radio':
          return (
            <div key={field.id} className="form-group">
              <label>{field.label}</label>
              {field.options.map((option, index) => (
                <div key={index} className="form-check">
                  <input
                    type="radio"
                    name={field.id}
                    value={option}
                    checked={formData[field.id] === option}
                    onChange={(e) => handleInputChange(field.id, e.target.value)}
                    className="form-check-input"
                  />
                  <label className="form-check-label">{option}</label>
                </div>
              ))}
            </div>
          );
        case 'date':
          return (
            <div key={field.id} className="form-group">
              <label>{field.label}</label>
              <input
                type="date"
                value={formData[field.id] || ''}
                onChange={(e) => handleInputChange(field.id, e.target.value)}
                className="form-control"
              />
            </div>
          );
        case 'file':
          return (
            <div key={field.id} className="form-group">
              <label>{field.label}</label>
              <input
                type="file"
                accept={field.fileType || '*'}
                onChange={(e) => handleInputChange(field.id, e.target.files[0])}
                className="form-control"
              />
            </div>
          );
        default:
          return null;
      }
    });
  };

  const renderFormContent = () => {
    if (formStatus === 'loading') {
      return <div>Loading form...</div>;
    }

    if (formStatus === 'expired') {
      return (
        <div className="form-status-message">
          <h2>Form Expired</h2>
          <p>This form has expired. Please contact the admin for further assistance.</p>
        </div>
      );
    }

    if (formStatus === 'inactive') {
      return (
        <div className="form-status-message">
          <h2>Form Inactive</h2>
          <p>This form is currently inactive. Please contact the admin for further assistance.</p>
        </div>
      );
    }

    if (formStatus === 'valid') {
      return (
        <div className="form-content">
          <h2>{form.title}</h2>
          <p>{form.description}</p>
          <div className="form-group form-group-full">
            <label>Email</label>
            <input
              type="email"
              name="submittedBy"
              id="submittedBy"
              placeholder="Enter your email"
              value={formData.submittedBy}
              onChange={(e) => handleInputChange('submittedBy', e.target.value)}
              className="form-control"
              required
            />
          </div>
          <div className="form-group form-group-full">
            <label>Phone</label>
            <PhoneInput
              country={'us'}
              value={formData.phone}
              onChange={handlePhoneChange}
              inputProps={{
                name: 'phone',
                required: true,
                autoFocus: false
              }}
              inputStyle={{ width: '100%' }}
              containerStyle={{ width: '100%' }}
            />
          </div>
          <div className="form-row">
            {renderFormFields()}
          </div>
          <div className="form-actions">
            <Btn attrBtn={{ color: 'primary', onClick: handleSubmit, disabled: isSubmitting }}>
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </Btn>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="form-page-container">
      <div className="form-page-content">
        {renderFormContent()}
      </div>
    </div>
  );
};

export default FormPage;

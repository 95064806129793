import React, { Fragment, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { getBatchNamesAndStudentCounts } from '../../../Services/batchService'; // Adjust the import path

const MixedChartClass = () => {
  const [chartData, setChartData] = useState({
    series: [{
      name: 'Student Count',
      type: 'bar',
      data: [] // Initialize with an empty array
    }],
    options: {
      chart: {
        type: 'bar',
        stacked: false,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          columnWidth: '60%',
          distributed: true
        }
      },
      fill: {
        opacity: 1
      },
      labels: [], // Initialize with an empty array
      markers: {
        size: 0
      },
      xaxis: {
        categories: [], // Initialize with an empty array
        type: 'category'
      },
      colors: ['#fb740d'], // Single color for the bar
      yaxis: {
        title: {
          text: 'Number of Students'
        },
        min: 0
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            if (typeof y !== 'undefined') {
              return y.toFixed(0) + ' students'; // Tooltips to show student count
            }
            return y;
          }
        }
      },
      dataLabels: {
        enabled: true
      }
    }
  });

  useEffect(() => {
    const fetchBatchData = async () => {
      try {
        const batchDetails = await getBatchNamesAndStudentCounts();
        console.log('Fetched Batch Details:', batchDetails); // Log the fetched data

        // Ensure batchDetails is an array
        if (!Array.isArray(batchDetails)) {
          throw new Error('Invalid data format');
        }

        // Prepare data for the chart
        const categories = batchDetails.map(batch => batch.batchName); // Extract batch names
        const data = batchDetails.map(batch => batch.studentCount); // Extract student counts

        console.log('Categories:', categories); // Debug output
        console.log('Data:', data); // Debug output

        setChartData(prevState => ({
          ...prevState,
          series: [{
            name: 'Student Count',
            type: 'bar',
            data
          }],
          options: {
            ...prevState.options,
            xaxis: {
              categories: categories
            },
            labels: categories
          }
        }));
      } catch (error) {
        console.error('Error fetching batch data:', error);
      }
    };

    fetchBatchData();
  }, []);

  return (
    <Fragment>
      <Col sm='12' xl='12' className='box-col-12'>
        <Card>
          <CardHeader className='text-center'>
            <h4>Batch Student Counts</h4>
          </CardHeader>
          <CardBody>
            <Row>
              <Col xs='12' sm='12' md='12'>
                <div id='mixedchart' style={{ width: '100%', height: '300px' }}>
                  <Chart 
                    options={chartData.options} 
                    series={chartData.series} 
                    type="bar" 
                    width='100%' 
                    height='100%' 
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default MixedChartClass;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, FormGroup, Input, InputGroup, InputGroupText, Label } from "reactstrap";
import { Mail, User, Phone } from "react-feather";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Image } from "../../AbstractElements";
import { CreateAccount, EmailAddress, SignIn, MobileNumber } from "../../Constant";
import MediaIcons from "../../CommonElements/Media";
import { IBRegister } from "../../Services/authService";
import { toast } from "react-toastify";

export default function RegistrationForm() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleRegister = async (e) => {
        e.preventDefault();
        try {
            const data = await IBRegister({ name, email, mobile });
            localStorage.setItem('token', data.token);
            toast.success("Registration Successful");
            navigate('/ib/students');
        } catch (err) {
            console.error(err);
            toast.error(err.message);
            setError('Registration failed, please try again.');
        }
    };

    var images = require.context("../../assets/images", true);
    const dynamicImage = (image) => {
        return images(`./${image}`);
    };

    return (
        <div>
            <div>
                <Link className='logo' to={`/dashboard/default`}>
                    <Image className='img-fluid for-light' src={dynamicImage(`logo/logo.svg`)} alt='registrationpage' body={true} />
                </Link>
            </div>
            <div className='login-card1'>
                <Form className='theme-form login-form' onSubmit={handleRegister}>
                    <div className='login-header text-center'>
                        <h4>{CreateAccount}</h4>
                        <h6>Register as a Referal Partner!</h6>
                    </div>

                    {error && <div className="alert alert-danger">{error}</div>}

                    <FormGroup>
                        <Label for="name">Full Name</Label>
                        <InputGroup>
                            <InputGroupText>
                                <User />
                            </InputGroupText>
                            <Input
                                type='text'
                                required
                                placeholder='Your Name'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <Label for="email">{EmailAddress}</Label>
                        <InputGroup>
                            <InputGroupText>
                                <Mail />
                            </InputGroupText>
                            <Input
                                type='email'
                                required
                                placeholder='example@ib.com'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </InputGroup>
                    </FormGroup>

                    <FormGroup>
                        <Label for="mobile">{MobileNumber}</Label>
                        <InputGroup className="mobile-input-group">
                            <InputGroupText>
                                <Phone />
                            </InputGroupText>
                            <div className="phone-input-container">
                                <PhoneInput
                                    country={'us'}
                                    value={mobile}
                                    onChange={(value) => setMobile(value)}
                                    inputStyle={{
                                        width: '100%',
                                        borderRadius: '0 0.375rem 0.375rem 0',
                                        height: 'calc(1.5em + 0.75rem + 2px)',
                                        paddingLeft: '48px' // Added padding to align text properly
                                    }}
                                    buttonStyle={{
                                        borderRadius: '0.375rem 0 0 0.375rem',
                                        height: 'calc(1.5em + 0.75rem + 2px)',
                                    }}
                                    containerClass="phone-input-container"
                                />
                            </div>
                        </InputGroup>
                    </FormGroup>

                    <FormGroup>
                        <Button type='submit' className='btn-block' color='primary'>
                            {CreateAccount}
                        </Button>
                    </FormGroup>
                    <div className='login-social-title'>
                        <h5>Or sign up with</h5>
                    </div>
                    <FormGroup>
                        <MediaIcons />
                    </FormGroup>
                    <p>
                        Already have an account?
                        <Link to={`/ib/login`} className='ms-2'>
                            {SignIn}
                        </Link>
                    </p>
                </Form>
            </div>
        </div>
    );
}

import React, { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';
import { studentRoutes, adminRoutes, ibRoutes } from './Routes'; // Separate routes for admin, students, and ib
import Layout1 from '../Layout/Layout'; // Layout for admin
import Layout2 from '../Layout2/Layout'; // Layout for students
import Layout3 from '../Layout3/Layout'; // Layout for ib

const LayoutRoutes = () => {
  // Combine all routes into a single array with their respective layouts
  const allRoutes = [
    ...adminRoutes.map(route => ({ ...route, Layout: Layout1 })),
    ...studentRoutes.map(route => ({ ...route, Layout: Layout2 })),
    ...ibRoutes.map(route => ({ ...route, Layout: Layout3 })),
  ];

  return (
    <Fragment>
      <Routes>
        {allRoutes.map(({ path, Component, Layout }, i) => (
          <Route key={i} element={<Layout />}>
            <Route path={path} element={Component} />
          </Route>
        ))}
      </Routes>
    </Fragment>
  );
};

export default LayoutRoutes;
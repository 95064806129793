import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import SmtpConfiguration from '../../Component/EmailSetting';
const SmtpConfigurationPage = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="settings" title="SMTP-Configuration" />
      <SmtpConfiguration />
    </Fragment>
  );
};
export default SmtpConfigurationPage;

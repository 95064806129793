import { Link, useNavigate } from "react-router-dom";
import { Button, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Lock, Mail } from "react-feather";
import { Image } from "../../AbstractElements";
import { CreateAccount, EmailAddress, ForgotPassword, Login, Password, RememberPassword, SignIn } from "../../Constant";
import MediaIcons from "../../CommonElements/Media";
import { useState } from "react";
import { login } from "../../Services/authService";  // Import the authService
import { forgotPasswordAdmin } from "../../Services/forgotPasswordService";  // Import the authService including forgotPassword
import { PUBLIC_URL } from "../../Constant";
import { toast } from "react-toastify";

export default function LoginForm() {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for forgot password modal
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState(""); // State for forgot password email
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const data = await login(email, password);
      localStorage.setItem('token', data.token);
      toast.success("Login Successfully");
      navigate('/dashboard');  // Navigate to the dashboard on success
    } catch (err) {
      console.error(err);
      toast.error(err.message);
      setError('Invalid credentials, please try again.');
    }
  };

  const handleForgotPassword = async () => {
    try {
      await forgotPasswordAdmin(forgotPasswordEmail);
      toast.success("Password reset instructions sent to your email.");
      setIsModalOpen(false);
    } catch (error) {
      toast.error("Error sending password reset email.");
      console.error("Forgot Password Error: ", error);
    }
  };

  var images = require.context("../../assets/images", true);
  const dynamicImage = (image) => {
    return images(`./${image}`);
  };

  return (
    <div>
      <div>
        <Link className='logo' to={`${PUBLIC_URL}/dashboard/default`}>
          <Image className='img-fluid for-light' src={dynamicImage(`logo/logo.svg`)} alt='looginpage' body={true} />
        </Link>
      </div>
      <div className='login-card1'>
        <Form className='theme-form login-form' onSubmit={handleLogin}>
          <div className='login-header text-center'>
            <h4>{Login}</h4>
            <h6>Welcome back Admin! Log in to your account.</h6>
          </div>

          {error && <div className="alert alert-danger">{error}</div>}

          <FormGroup>
            <Label>{EmailAddress}</Label>
            <InputGroup>
              <InputGroupText>
                <Mail />
              </InputGroupText>
              <Input
                type='email'
                required
                placeholder='Test@gmail.com'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label>{Password}</Label>
            <InputGroup>
              <InputGroupText>
                <Lock />
              </InputGroupText>
              <Input
                type={isPasswordVisible ? "text" : "password"}
                name='login[password]'
                required
                placeholder='*********'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className='show-hide' onClick={togglePasswordVisibility}>
                <span className={!isPasswordVisible ? "show" : ""}></span>
              </div>
            </InputGroup>
          </FormGroup>

          <FormGroup className='login-btn'>
            <div className='checkbox'>
              <Input id='checkbox1' type='checkbox' />
              <Label className='text-muted' for='checkbox1'>
                {RememberPassword}
              </Label>
            </div>
            <span className='link' onClick={() => setIsModalOpen(true)}>
              {ForgotPassword}
            </span>
          </FormGroup>
          <FormGroup>
            <Button type='submit' className='btn-block' color='primary'>
              {SignIn}
            </Button>
          </FormGroup>
          <div className='login-social-title'>
            <h5>Sign in with Email</h5>
          </div>
          <FormGroup>
            <MediaIcons />
          </FormGroup>
          <p>
            Don't have an account?
            <Link href={`${PUBLIC_URL}/create-pwd`} className='ms-2'>
              {CreateAccount}
            </Link>
          </p>
        </Form>
      </div>

      {/* Forgot Password Modal */}
      <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)}>
        <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>
          Forgot Password
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="forgotPasswordEmail">Enter your email address:</Label>
            <Input
              type="email"
              id="forgotPasswordEmail"
              value={forgotPasswordEmail}
              onChange={(e) => setForgotPasswordEmail(e.target.value)}
              placeholder="example@student.com"
              required
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleForgotPassword}>
            Send Reset Link
          </Button>{' '}
          <Button color="secondary" onClick={() => setIsModalOpen(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import StudentPage from '../../Component/Student Page';

const Students = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Pages" title="Students" />
      <StudentPage />
    </Fragment>
  );
};
export default Students;
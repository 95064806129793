const axios = require('axios');

// Base URL for the API
const API_URL =  process.env.REACT_APP_API_URL ||  "api" 

// Get all forms
exports.getAllForms = async () => {
  try {
    const response = await axios.get(`${API_URL}/forms`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching forms:', error);
    throw error;
  }
};

// Get a form by ID
exports.getFormById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/forms/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching form with ID ${id}:`, error);
    throw error;
  }
};

// Create a new form
exports.createForm = async (formData) => {
  try {
    const response = await axios.post(`${API_URL}/forms`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating form:', error);
    throw error;
  }
};

// Update a form by ID
exports.updateForm = async (id, formData) => {
  try {
    const response = await axios.put(`${API_URL}/forms/${id}`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error updating form with ID ${id}:`, error);
    throw error;
  }
};

// Delete a form by ID
exports.deleteForm = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/forms/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error deleting form with ID ${id}:`, error);
    throw error;
  }
};

// Submit a form
exports.submitForm = async (id, formResponses) => {
  try {
    const response = await axios.post(`${API_URL}/forms/${id}/submit`, formResponses, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error submitting form with ID ${id}:`, error);
    throw error;
  }
};

// Get all responses for a form
exports.getFormResponses = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/forms/${id}/responses`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching responses for form with ID ${id}:`, error);
    throw error;
  }
};

import React, { useState, useEffect } from 'react';
import { Btn } from '../../AbstractElements';
import { getAllForms, createForm, updateForm } from '../../Services/formService'; // Import the form service
import { toast } from 'react-toastify';

const initialComponents = [
  { id: 'input', label: 'Input Field', type: 'input', placeholder: 'Enter text' },
  { id: 'textarea', label: 'Textarea', type: 'textarea', placeholder: 'Enter text' },
  {
    id: 'dropdown',
    label: 'Dropdown',
    type: 'dropdown',
    options: ['Option 1', 'Option 2', 'Option 3'],
  },
  { id: 'checkbox', label: 'Checkbox', type: 'checkbox', options: ['Option 1', 'Option 2'] },
  { id: 'radio', label: 'Radio Button', type: 'radio', options: ['Option 1', 'Option 2'] },
  { id: 'date', label: 'Date Picker', type: 'date' },
  { id: 'file', label: 'Upload File', type: 'file', fileType: '.png, .jpg, .gif' },
  { id: 'button', label: 'Button', type: 'button' },
];

const FormBuilder = () => {
  const [formFields, setFormFields] = useState([]);
  const [formTitle, setFormTitle] = useState('Sample Form');
  const [formDescription, setFormDescription] = useState('Form Description');
  const [formId, setFormId] = useState('');

  useEffect(() => {
    // Fetch the saved form from the server when the component mounts
    getAllForms()
      .then((response) => {
        if (response.length > 0) {
          // const latestForm = response[0]; // Assuming you want the latest form
          // setFormFields(latestForm.fields);
          // setFormTitle(latestForm.title);
          // setFormDescription(latestForm.description);
          // setFormId(latestForm._id);
        }
      })
      .catch((error) => {
        console.error('Error fetching the form:', error);
      });

    // Check screen size and show an alert if it's a small device
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        // Example breakpoint for small devices
        toast.warn('This application is not fully optimized for small devices.');
      }
    };

    // Initial check
    handleResize();

    // Add event listener for resizing
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleDragStart = (event, component) => {
    event.dataTransfer.setData('component', JSON.stringify(component));
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const component = JSON.parse(event.dataTransfer.getData('component'));
    setFormFields([...formFields, { ...component, id: `${component.id}-${Date.now()}` }]);
  };

  const handleDragOver = (event) => {
    event.preventDefault(); // Necessary to allow a drop
  };

  const removeField = (id) => {
    setFormFields(formFields.filter((field) => field.id !== id));
  };

  const updateField = (id, property, value) => {
    const updatedFields = formFields.map((field) =>
      field.id === id ? { ...field, [property]: value } : field
    );
    setFormFields(updatedFields);
  };

  const handleSave = () => {
    const formData = {
      title: formTitle,
      description: formDescription,
      fields: formFields,
    };

    if (formId) {
      // Update the existing form
      updateForm(formId, formData)
        .then((response) => {
          toast.success('Form has been updated!');
        })
        .catch((error) => {
          console.error('Error updating the form:', error);
          toast.error('Failed to update the form.');
        });
    } else {
      // Create a new form
      createForm(formData)
        .then((response) => {
          setFormId(response._id); // Set the form ID after saving
          toast.success('Form has been created!');
        })
        .catch((error) => {
          console.error('Error saving the form:', error);
          toast.error('Failed to save the form.');
        });
    }
  };

  const handleCopyLink = () => {
    const formLink = `${window.location.origin}/form/${formId}`;
    navigator.clipboard
      .writeText(formLink)
      .then(() => toast.success('Form link copied to clipboard!'))
      .catch((error) => toast.error('Failed to copy link:', error));
  };

  return (
    <div
      className="form-builder-container"
      style={{ display: 'flex', height: '100vh', fontFamily: 'Arial, sans-serif' }}
    >
      {/* Sidebar */}
      <div
        style={{
          width: '23%',
          padding: '20px',
          backgroundColor: '#F4F6FD',
          borderRight: '1px solid #ddd',
        }}
      >
        <h3>Components</h3>
        <div>
          {initialComponents.map((component) => (
            <div
              key={component.id}
              draggable
              onDragStart={(event) => handleDragStart(event, component)}
              style={{
                padding: '10px',
                margin: '0 0 10px 0',
                backgroundColor: '#fff',
                border: '1px solid #ddd',
                cursor: 'pointer',
              }}
            >
              {component.label}
            </div>
          ))}
        </div>
        <Btn attrBtn={{ color: 'primary', onClick: handleSave }}>Save Form</Btn>
        <Btn attrBtn={{ color: 'secondary', onClick: handleCopyLink }} disabled={!formId}>
          Copy Link
        </Btn>
      </div>

      {/* Form Builder Area */}
      <div
        style={{ flex: 1, padding: '20px', minHeight: '400px', backgroundColor: '#fff' }}
        onDrop={(event) => handleDrop(event)}
        onDragOver={handleDragOver}
      >
        <input
          type="text"
          value={formTitle}
          onChange={(e) => setFormTitle(e.target.value)}
          style={{
            fontSize: '24px',
            fontWeight: 'bold',
            marginBottom: '20px',
            width: '100%',
            padding: '10px',
            boxSizing: 'border-box',
            borderRadius: '5px',
            border: '1px solid #ced4da',
          }}
          placeholder="Edit Form Title"
        />
        <textarea
          value={formDescription}
          onChange={(e) => setFormDescription(e.target.value)}
          style={{
            fontSize: '16px',
            marginBottom: '20px',
            width: '100%',
            padding: '10px',
            boxSizing: 'border-box',
            borderRadius: '5px',
            border: '1px solid #ced4da',
          }}
          placeholder="Edit Form Description"
        />

        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px' }}>
          {formFields.map((field) => (
            <div
              key={field.id}
              style={{
                marginBottom: '20px',
                padding: '10px',
                backgroundColor: '#fff',
                border: '1px solid #ddd',
                borderRadius: '5px',
              }}
            >
              {field.type === 'input' && (
                <div>
                  <input
                    type="text"
                    value={field.label}
                    onChange={(e) => updateField(field.id, 'label', e.target.value)}
                    style={{
                      display: 'block',
                      marginBottom: '10px',
                      padding: '8px',
                      border: '1px solid #ced4da',
                      borderRadius: '5px',
                    }}
                    placeholder="Edit Label"
                  />
                  <input
                    type="text"
                    placeholder={field.placeholder}
                    onChange={(e) => updateField(field.id, 'placeholder', e.target.value)}
                    value={field.placeholder}
                    style={{
                      width: '100%',
                      padding: '10px',
                      boxSizing: 'border-box',
                      borderRadius: '5px',
                      border: '1px solid #ced4da',
                    }}
                  />
                </div>
              )}
              {field.type === 'textarea' && (
                <div>
                  <input
                    type="text"
                    value={field.label}
                    onChange={(e) => updateField(field.id, 'label', e.target.value)}
                    style={{
                      display: 'block',
                      marginBottom: '10px',
                      padding: '8px',
                      border: '1px solid #ced4da',
                      borderRadius: '5px',
                    }}
                    placeholder="Edit Label"
                  />
                  <textarea
                    placeholder={field.placeholder}
                    onChange={(e) => updateField(field.id, 'placeholder', e.target.value)}
                    value={field.placeholder}
                    style={{
                      width: '100%',
                      padding: '10px',
                      boxSizing: 'border-box',
                      borderRadius: '5px',
                      border: '1px solid #ced4da',
                    }}
                  />
                </div>
              )}
              {field.type === 'dropdown' && (
                <div>
                  <input
                    type="text"
                    value={field.label}
                    onChange={(e) => updateField(field.id, 'label', e.target.value)}
                    style={{
                      display: 'block',
                      marginBottom: '10px',
                      padding: '8px',
                      border: '1px solid #ced4da',
                      borderRadius: '5px',
                    }}
                    placeholder="Edit Label"
                  />
                  {field.options.map((option, idx) => (
                    <input
                      key={idx}
                      type="text"
                      value={option}
                      onChange={(e) => {
                        const newOptions = [...field.options];
                        newOptions[idx] = e.target.value;
                        updateField(field.id, 'options', newOptions);
                      }}
                      style={{
                        display: 'block',
                        marginBottom: '5px',
                        padding: '8px',
                        border: '1px solid #ced4da',
                        borderRadius: '5px',
                      }}
                      placeholder="Edit Option"
                    />
                  ))}
                  <button
                    onClick={() =>
                      updateField(field.id, 'options', [...field.options, 'New Option'])
                    }
                    style={{
                      marginTop: '10px',
                      padding: '5px 10px',
                      backgroundColor: '#007bff',
                      color: '#fff',
                      border: 'none',
                      borderRadius: '5px',
                      cursor: 'pointer',
                    }}
                  >
                    Add Option
                  </button>
                </div>
              )}
              {field.type === 'checkbox' && (
                <div>
                  <input
                    type="text"
                    value={field.label}
                    onChange={(e) => updateField(field.id, 'label', e.target.value)}
                    style={{
                      display: 'block',
                      marginBottom: '10px',
                      padding: '8px',
                      border: '1px solid #ced4da',
                      borderRadius: '5px',
                    }}
                    placeholder="Edit Label"
                  />
                  {field.options.map((option, idx) => (
                    <div
                      key={idx}
                      style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}
                    >
                      <input
                        type="checkbox"
                        style={{ marginRight: '10px' }}
                        disabled // to show the checkbox for design purpose, but not actually clickable
                      />
                      <input
                        type="text"
                        value={option}
                        onChange={(e) => {
                          const newOptions = [...field.options];
                          newOptions[idx] = e.target.value;
                          updateField(field.id, 'options', newOptions);
                        }}
                        style={{
                          padding: '8px',
                          border: '1px solid #ced4da',
                          borderRadius: '5px',
                        }}
                        placeholder="Edit Option"
                      />
                    </div>
                  ))}
                  <button
                    onClick={() =>
                      updateField(field.id, 'options', [...field.options, 'New Option'])
                    }
                    style={{
                      marginTop: '10px',
                      padding: '5px 10px',
                      backgroundColor: '#007bff',
                      color: '#fff',
                      border: 'none',
                      borderRadius: '5px',
                      cursor: 'pointer',
                    }}
                  >
                    Add Option
                  </button>
                </div>
              )}

              {field.type === 'radio' && (
                <div>
                  <input
                    type="text"
                    value={field.label}
                    onChange={(e) => updateField(field.id, 'label', e.target.value)}
                    style={{
                      display: 'block',
                      marginBottom: '10px',
                      padding: '8px',
                      border: '1px solid #ced4da',
                      borderRadius: '5px',
                    }}
                    placeholder="Edit Label"
                  />
                  {field.options.map((option, idx) => (
                    <div
                      key={idx}
                      style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}
                    >
                      <input
                        type="radio"
                        name={field.id} // Ensure radios are grouped
                        style={{ marginRight: '10px' }}
                        disabled // Display purpose, not clickable
                      />
                      <input
                        type="text"
                        value={option}
                        onChange={(e) => {
                          const newOptions = [...field.options];
                          newOptions[idx] = e.target.value;
                          updateField(field.id, 'options', newOptions);
                        }}
                        style={{
                          padding: '8px',
                          border: '1px solid #ced4da',
                          borderRadius: '5px',
                        }}
                        placeholder="Edit Option"
                      />
                    </div>
                  ))}
                  <button
                    onClick={() =>
                      updateField(field.id, 'options', [...field.options, 'New Option'])
                    }
                    style={{
                      marginTop: '10px',
                      padding: '5px 10px',
                      backgroundColor: '#007bff',
                      color: '#fff',
                      border: 'none',
                      borderRadius: '5px',
                      cursor: 'pointer',
                    }}
                  >
                    Add Option
                  </button>
                </div>
              )}

              {field.type === 'date' && (
                <div>
                  <input
                    type="text"
                    value={field.label}
                    onChange={(e) => updateField(field.id, 'label', e.target.value)}
                    style={{
                      display: 'block',
                      marginBottom: '10px',
                      padding: '8px',
                      border: '1px solid #ced4da',
                      borderRadius: '5px',
                    }}
                    placeholder="Edit Label"
                  />
                  <input
                    type="date"
                    onChange={(e) => updateField(field.id, 'date', e.target.value)}
                    value={field.date}
                    style={{
                      width: '100%',
                      padding: '10px',
                      boxSizing: 'border-box',
                      borderRadius: '5px',
                      border: '1px solid #ced4da',
                    }}
                  />
                </div>
              )}
              {field.type === 'file' && (
                <div>
                  <input
                    type="text"
                    value={field.label}
                    onChange={(e) => updateField(field.id, 'label', e.target.value)}
                    style={{
                      display: 'block',
                      marginBottom: '10px',
                      padding: '8px',
                      border: '1px solid #ced4da',
                      borderRadius: '5px',
                    }}
                    placeholder="Edit Label"
                  />
                  <input
                    type="text"
                    value={field.fileType}
                    onChange={(e) => updateField(field.id, 'fileType', e.target.value)}
                    style={{
                      display: 'block',
                      padding: '8px',
                      border: '1px solid #ced4da',
                      borderRadius: '5px',
                    }}
                    placeholder="Edit File Types (e.g., .png, .jpg)"
                  />
                  <div
                    style={{
                      textAlign: 'center',
                      padding: '20px',
                      border: '2px dashed #ced4da',
                      borderRadius: '5px',
                      marginTop: '10px',
                    }}
                  >
                    <label style={{ cursor: 'pointer', color: '#007bff' }}>
                      Upload a file or drag and drop
                    </label>
                    <p style={{ marginTop: '5px', color: '#6c757d' }}>
                      Allowed file types: {field.fileType}
                    </p>
                  </div>
                </div>
              )}
              {field.type === 'button' && (
                <div>
                  <input
                    type="text"
                    value={field.label}
                    onChange={(e) => updateField(field.id, 'label', e.target.value)}
                    style={{
                      display: 'block',
                      marginBottom: '10px',
                      padding: '8px',
                      border: '1px solid #ced4da',
                      borderRadius: '5px',
                    }}
                    placeholder="Edit Button Label"
                  />
                  <button
                    style={{
                      width: '100%',
                      padding: '10px',
                      backgroundColor: '#007bff',
                      color: '#fff',
                      border: 'none',
                      borderRadius: '5px',
                      cursor: 'pointer',
                    }}
                  >
                    {field.label}
                  </button>
                </div>
              )}
              <button
                onClick={() => removeField(field.id)}
                style={{
                  marginTop: '10px',
                  color: 'red',
                  backgroundColor: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                }}
              >
                Delete
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FormBuilder;

import React, { Fragment, useState, useEffect } from 'react';
import {
    Card, CardBody, CardHeader, Input, Table, Spinner
} from 'reactstrap';
import { H5, H6 } from '../../AbstractElements';
import { getStudentStudyMaterials } from '../../Services/StudentPersonal'; // Ensure the service is properly imported
import { toast } from 'react-toastify';

const StudentStudyMaterial = () => {
    const [studyMaterials, setStudyMaterials] = useState([]);
    const [filteredMaterials, setFilteredMaterials] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state for fetching study materials

    useEffect(() => {
        fetchStudyMaterials();
    }, []);

    useEffect(() => {
        filterMaterials();
    }, [searchQuery, studyMaterials]);

    const fetchStudyMaterials = async () => {
        setLoading(true);
        setError(null);
        try {
            const materials = await getStudentStudyMaterials();
            setStudyMaterials(materials);
            setFilteredMaterials(materials);
        } catch (error) {
            setError('Error fetching study materials. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const filterMaterials = () => {
        if (searchQuery) {
            const lowercasedQuery = searchQuery.toLowerCase();
            const filtered = studyMaterials.filter(material =>
                material.title.toLowerCase().includes(lowercasedQuery)
            );
            setFilteredMaterials(filtered);
        } else {
            setFilteredMaterials(studyMaterials);
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    return (
        <Fragment>
            <Card style={{ width: '95%', margin: '0 auto', paddingRight: '5px' }} className="dash-35">
                <Card className='study-material-management'>
                    <CardHeader className="card-no-border">
                        <H5 attrH5={{ className: 'mb-0' }}>Study Materials</H5>
                    </CardHeader>
                    <CardBody>
                        <div className="d-flex justify-content-end mb-3">
                            <Input
                                type="text"
                                placeholder="Search by title..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                                style={{ width: '300px' }}
                            />
                        </div>

                        {/* Display loading spinner or error message */}
                        {loading ? (
                            <div className="text-center">
                                <Spinner color="primary" />
                            </div>
                        ) : error ? (
                            <div className="text-center text-danger">
                                {error}
                            </div>
                        ) : (
                            <Table className="table-bordernone" responsive>
                                <thead>
                                    <tr>
                                        <th><H5>Title</H5></th>
                                        <th><H5>Link</H5></th>
                                        <th><H5>Description</H5></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredMaterials.map((material) => (
                                        <tr key={material._id}>
                                            <td><H6>{material.title}</H6></td>
                                            <td><H6><a href={material.link} target="_blank" rel="noopener noreferrer">{material.link}</a></H6></td>
                                            <td><H6>{material.description}</H6></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
                    </CardBody>
                </Card>
            </Card>
        </Fragment>
    );
};

export default StudentStudyMaterial;

import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import WapikonApiSetup from '../../Component/WapikonSetup';
const WapikonApiSetupPage = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="settings" title="WapikonApiSetup" />
      <WapikonApiSetup />
    </Fragment>
  );
};
export default WapikonApiSetupPage;

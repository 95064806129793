import React, { Fragment, useState, useEffect } from 'react';
import {
  Card, CardBody, CardHeader, Input, Table, Button, Modal,
  ModalHeader, ModalBody, ModalFooter, FormGroup, Label
} from 'reactstrap';
import { H5, H6 } from '../../AbstractElements';
import { FaPlus } from 'react-icons/fa';
import { LuRefreshCcw } from 'react-icons/lu';
import {
  getBatches,
  updateBatch,
  createBatch,
  deleteBatch,
  addStudentsToBatch,
  removeStudentsFromBatch,
} from '../../Services/batchService';
import { getStudents } from '../../Services/studentService';
import { toast } from 'react-toastify';

const BatchPage = () => {
  const [batches, setBatches] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [students, setStudents] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [currentBatch, setCurrentBatch] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [newBatch, setNewBatch] = useState({
    batchName: '',
    startDate: '',
    batchNote: ''
  });

  useEffect(() => {
    fetchBatches();
    fetchStudents();
  }, []);

  const fetchBatches = async () => {
    try {
      const data = await getBatches();
      setBatches(data || []);
    } catch (error) {
      console.error('Error fetching batches:', error);
    }
  };

  const fetchStudents = async () => {
    try {
      const data = await getStudents();
      setStudents(data.students || []);
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  };

  const toggleEditModal = () => setEditModal(!editModal);
  const toggleAddModal = () => setAddModal(!addModal);

  const handleEditClick = (batch) => {
    setCurrentBatch(batch);
    toggleEditModal();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentBatch(prev => ({ ...prev, [name]: value }));
  };

  const handleNewBatchChange = (e) => {
    const { name, value } = e.target;
    setNewBatch(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    try {
      if (currentBatch?._id) {
        await updateBatch(currentBatch._id, currentBatch);
        fetchBatches();
        toggleEditModal();
        toast.success("Batch Updated");
      }
    } catch (error) {
      console.error('Error updating batch:', error);
      toast.error('Error updating batch');
    }
  };

  const handleAddBatch = async () => {
    try {
      await createBatch(newBatch);
      fetchBatches();
      toggleAddModal();
      setNewBatch({ batchName: '', startDate: '', batchNote: '' });
      toast.success("Batch Added");
    } catch (error) {
      console.error('Error adding batch:', error);
      toast.error('Error adding batch');
    }
  };

  const handleRemoveBatch = async (batchId) => {
    try {
      await deleteBatch(batchId);
      setBatches(batches.filter(batch => batch._id !== batchId));
      toast.success("Batch Deleted");
    } catch (error) {
      console.error('Error removing batch:', error);
      toast.error('Error removing batch');
    }
  };

  const handleAddStudent = async (student) => {
    console.log('handleAddStudent function triggered'); // Debugging log
    try {
      if (currentBatch?._id) {
        console.log('Attempting to add student with ID:', student._id); // Debugging log
        const updatedBatch = await addStudentsToBatch(currentBatch._id, [student._id]);
        console.log('API Response:', updatedBatch); // Debugging log
        setCurrentBatch(updatedBatch);
        toast.success("Student Added");
      }
    } catch (error) {
      console.error('Error adding student to batch:', error);
      toast.error('Error adding student');
    }
  };
  
  
  
  
  const handleRemoveStudent = async (studentId) => {
    try {
      if (currentBatch?._id) {
        const updatedBatch = await removeStudentsFromBatch(currentBatch._id, [studentId]);
        setCurrentBatch(updatedBatch);
        toast.success("Student Removed");
      }
    } catch (error) {
      console.error('Error removing student from batch:', error);
      toast.error('Error removing student');
    }
  };
  

  const filteredStudents = students.filter(student =>
    student.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    student.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Fragment>
      <Card style={{ width: '95%', margin: '0 auto', paddingRight: '5px' }} className="dash-35">
        <Card className='batch-management'>
          <CardHeader className="card-no-border">
            <div className="d-flex flex-wrap justify-content-between align-items-center gap-2 mb-3">
              <div className="d-flex flex-wrap gap-2">
                <Button outline color="primary" onClick={fetchBatches}>
                  <LuRefreshCcw /> Refresh
                </Button>
                <Button color="primary" onClick={toggleAddModal}>
                  <FaPlus /> Add Batch
                </Button>
              </div>
              <Input
                type="text"
                style={{ maxWidth: '300px' }}
                placeholder="Search here..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <H5 attrH5={{ className: 'mb-0' }}>Batch Management</H5>
          </CardHeader>
          <CardBody>
            <Table className="table-bordernone">
              <thead>
                <tr>
                  <th><H5>Batch Name</H5></th>
                  <th><H5>Start Date</H5></th>
                  <th><H5>Batch Note</H5></th>
                  <th><H5>Students</H5></th>
                  <th><H5>Actions</H5></th>
                </tr>
              </thead>
              <tbody>
                {batches.map(batch => (
                  <tr key={batch._id}>
                    <td><H6>{batch.batchName}</H6></td>
                    <td><H6>{batch.startDate}</H6></td>
                    <td><H6>{batch.batchNote}</H6></td>
                    <td>
                      {batch.students.map((student, index) => (
                        <H6 key={student._id}> {index + 1}. {student.name}</H6>
                      ))}
                    </td>
                    <td className='d-flex gap-2'>
                      <Button color="primary" onClick={() => handleEditClick(batch)}>Edit</Button>
                      <Button color="danger" onClick={() => handleRemoveBatch(batch._id)}>Delete</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Card>

      {/* Edit Modal */}
      {currentBatch && (
        <Modal isOpen={editModal} toggle={toggleEditModal}>
          <ModalHeader toggle={toggleEditModal}>Edit Batch</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="batchName">Batch Name</Label>
              <Input
                type="text"
                name="batchName"
                id="batchName"
                value={currentBatch.batchName || ''}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="startDate">Start Date</Label>
              <Input
                type="date"
                name="startDate"
                id="startDate"
                value={currentBatch.startDate || ''}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="batchNote">Batch Note</Label>
              <Input
                type="textarea"
                name="batchNote"
                id="batchNote"
                value={currentBatch.batchNote || ''}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Students in Batch</Label>
              {currentBatch.students.map(student => (
                <div key={student._id} className="d-flex gap-2 justify-content-between align-items-center">
                  <H6>{student.name} - {student.email}</H6>
                  <Button color="danger" size="sm" onClick={() => handleRemoveStudent(student._id)}>Remove</Button>
                </div>
              ))}
            </FormGroup>
            <FormGroup>
              <Label for="searchStudent">Search and Add Student</Label>
              <Input
                type="text"
                name="searchStudent"
                id="searchStudent"
                placeholder="Search for students..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
              />
              <div className="search-results mt-2 d-flex flex-column gap-1">
                {isFocused && (
                  filteredStudents.length > 0 ? (
                    filteredStudents.map((student,idx) => (
                      <div key={idx} className="d-flex justify-content-between align-items-center">
                        <h6>{student.name} - {student.email}</h6>
                        <Button color="primary" size="sm" onClick={() => handleAddStudent(student)}>Add</Button>
                      </div>
                    ))
                  ) : (
                    <p>No students found.</p>
                  )
                )}
              </div>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleSave}>Save</Button>{' '}
            <Button color="secondary" onClick={toggleEditModal}>Cancel</Button>
          </ModalFooter>
        </Modal>
      )}

      {/* Add Batch Modal */}
      <Modal isOpen={addModal} toggle={toggleAddModal}>
        <ModalHeader toggle={toggleAddModal}>Add Batch</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="batchName">Batch Name</Label>
            <Input
              type="text"
              name="batchName"
              id="batchName"
              value={newBatch.batchName}
              onChange={handleNewBatchChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="startDate">Start Date</Label>
            <Input
              type="date"
              name="startDate"
              id="startDate"
              value={newBatch.startDate}
              onChange={handleNewBatchChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="batchNote">Batch Note</Label>
            <Input
              type="textarea"
              name="batchNote"
              id="batchNote"
              value={newBatch.batchNote}
              onChange={handleNewBatchChange}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleAddBatch}>Add Batch</Button>{' '}
          <Button color="secondary" onClick={toggleAddModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default BatchPage;
